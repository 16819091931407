<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <select
      v-model="text"
      @change="change"
      class="form-control select-box">
      <option
        v-for="(item, index) in options"
        :key="index"
        :selected="item.selected">
        {{ item.text }}
      </option>
    </select>
    <small
      class="text-danger"
      v-if="messageError && isError && isDirty">
      {{ messageError }}
    </small>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: null,
      isDirty: false,
    };
  },
  props: {
    modelValue: {
      default: null,
    },
    model: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    defaultOption: {
      type: Boolean,
      default: true,
    },
    defaultOptionValue: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    messageError: {
      type: String,
      default: null,
    },
  },
  created() {
    this.init();
  },
  watch: {
    options() {
      this.init();
    },
    model() {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.model !== null && this.options.length) {
        const item = this.options.find(x => x.value === this.model);
        if (item) {
          this.text = item.text;
        } else {
          this.text = this.options[0].text;
        }
      } else if (this.options.length && this.defaultOptionValue) {
        this.text = this.options.find(x => x.value === this.defaultOptionValue).text;
      } else if (this.options.length && this.defaultOption) {
        this.text = this.options[0].text;
      } else if (this.model === null) {
        this.text = '';
      }
    },
    change() {
      if (!this.isDirty) {
        this.isDirty = true;
      }
      const option = this.options.find(x => x.text === this.text);
      this.$emit('update:modelValue', option.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0px;
  .select-box {
    cursor: pointer;
  }
}
</style>
