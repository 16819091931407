<template>
  <div class="product-comment-input-box">
    <div v-if="!isCommentActive">
      <img
        class="comment-icon"
        @click="enableComment"
        src="@/assets/icons/comment-write.svg">
    </div>
    <div v-else>
      <div>
        <InputBox
          :modelValue="comment"
          @update:modelValue="comment = $event"
          type="text"
          :isTextarea="true">
        </InputBox>
      </div>
      <div class="col-md-12">
        <div class="row m-0 text-center">
          <div class="col-md-6 mt-3">
            <button
              @click="cancel"
              type="button"
              class="btn btn-danger product-comment-button">{{ content[lang].cancel }}
            </button>
          </div>
          <div class="col-md-6 mt-3">
            <button
              @click="create"
              type="button"
              :disabled="commentError"
              class="btn btn-success product-comment-button">{{ content[lang].create }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputBox from '../InputBox';

export default {
  data () {
    return {
      comment: '',
      isCommentActive: false,

      content: {
        ru: {
          title: 'Напишите',
          create: 'Создать',
          cancel: 'Отменить',
          needAuthorize: 'Нужно войти в аккаунт',
        },
        en: {
          title: 'Write',
          create: 'Create',
          cancel: 'Cancel',
          needAuthorize: 'Need to login in account',
        },
      },
    }
  },
  components: {
    InputBox,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    commentError() {
      return !this.comment.length;
    },
  },
  methods: {
    enableComment() {
      if (!this.isAuthorized) {
        this.$store.dispatch('setNotification', { content: this.content[this.lang].needAuthorize });
        return;
      }
      this.isCommentActive = true;
    },
    create() {
      this.$emit('create', this.comment);
      this.clear();
    },
    cancel() {
      this.clear();
    },
    clear() {
      this.isCommentActive = false;
      this.comment = '';
    },
  },
}
</script>

<style scoped lang="scss">
.product-comment-input-box {
  position: relative;
  .comment-icon {
    width: 25px;
    cursor: pointer;
  }
  @media screen and (min-width: 380px) and (max-width: 960px) {
    .product-comment-button {
      min-width: 100px;
    }
  }
  @media screen and (min-width: 961px) {
    .product-comment-button {
      min-width: 150px;
    }
  }
}
</style>
