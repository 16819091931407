<template>
  <div class="product-block block-default">
    <div class="product-header">
      <ProfileImage
        :userId="product.userId"
        :userName="product.userName"
        :image="product.userImage">
      </ProfileImage>
    </div>
    <div
      class="product-body"
      :class="{ pointer: isPublicList }"
      v-on="isPublicList ? { click: productView } : {}">
      <div class="product-card bolder text-center">
        <span 
          v-on="!isPublic && product.status === productStatus.approved ? { click: productView } : {}"
          class="product-name block-hover">
          {{ product.name }}
        </span>
      </div>
      <div v-if="image" class="product-card">
        <div class="product-image-card">
          <img v-on="isPublicList ? {} : { click: showImage }" class="product-image" :src="image">
        </div>
      </div>
      <div class="product-card">
        <span>{{ content[lang].type }}: </span>
        <span>{{ lang === 'en' ? product.productType.name : product.productType.description }}</span>
      </div>
      <div class="product-card">
        <span>{{ content[lang].mark }}: </span>
        <span>{{ productMarkContent[lang][product.mark] }}</span>
      </div>
      <div class="product-card">
        <span>{{ content[lang].created }}: </span>
        <span>{{ dateUTCToLocalFunc(product.created) }}</span>
      </div>
      <div v-if="!isPublicList" class="product-card">
        <div>{{ content[lang].description }}: </div>
        <div class="text-pre-wrap">{{ product.description }}</div>
      </div>
      <div v-if="(isUser || isAdmin) && product.updated" class="product-card">
        <span>{{ content[lang].updated }}: </span>
        <span>{{ dateUTCToLocalFunc(product.updated) }}</span>
      </div>
      <div v-if="isUser || isAdmin" class="product-card">
        <span>{{ content[lang].status }}: </span>
        <span 
          class="bolder"
          :style="{ color: productStatusColor }">
          {{ content[lang].isApprovedContent[product.status] }}
        </span>
      </div>
      <div v-if="isUser && product.status === productStatus.new" class="product-card text-center">
        <button
          @click="pendingProductByUser"
          type="button"
          class="btn button-default btn-info">{{ content[lang].pendingProductByUser }}
        </button>
      </div>
      <div v-if="(isUser || isAdmin) && product.adminComment" class="product-card">
        <span>{{ content[lang].adminComment }}: </span>
        <span>{{ product.adminComment }}</span>
      </div>
    </div>
    <div class="product-footer">
      <div class="col-12">
        <div class="row">
          <div class="mt-2 p-0 col-6">
            <div class="likes">
              <span>
                <span @click="like" class="icon">
                  <img v-if="product.userContextIsLike === true" src="@/assets/icons/thumbs-up-filled.svg">
                  <img v-else src="@/assets/icons/thumbs-up.svg">
                </span>
                <span>{{ product.likes }}</span>
              </span>
              <span class="dislike">
                <span @click="dislike" class="icon">
                  <img v-if="product.userContextIsLike === false" src="@/assets/icons/thumbs-down-filled.svg">
                  <img v-else src="@/assets/icons/thumbs-down.svg">
                </span>
                <span>{{ product.dislikes }}</span>
              </span>
            </div>
          </div>
          <div v-if="product.status === productStatus.approved" class="mt-2 p-0 col-6 text-right">
            <FacebookShare :url="productUrl"></FacebookShare>
          </div>
        </div>
      </div>
    </div>
    <div class="product-comments mt-2">
      <div class="mt-3">
        <div>
          <ProductCommentBox
            :productId="product.id"
            :productCommentsCount="product.productCommentsCount"
            :isActive="product.status === productStatus.approved">
          </ProductCommentBox>
        </div>
      </div>
    </div>
    <div v-if="isUser" class="col-md-12 pt-3 text-center">
      <div v-if="product.status === productStatus.new || product.status === productStatus.rejected" class="row mt-0">
        <div class="col-md-6 mb-3">
          <button
            @click="deleteProduct"
            type="button"
            class="btn button-default btn-danger">{{ content[lang].delete }}
          </button>
        </div>
        <div class="col-md-6 mb-3">
          <button
            @click="updateProduct"
            type="button"
            class="btn button-default btn-success">{{ content[lang].update }}
          </button>
        </div>
      </div>
      <div v-else class="col-md-12 mb-3">
        <button
          @click="deleteProduct"
          type="button"
          class="btn button-default btn-danger">{{ content[lang].delete }}
        </button>
      </div>
    </div>
    <div v-else-if="isAdmin && product.status !== productStatus.new" class="pt-3 text-center">
      <ProductApprove
        @approveProduct="approveProduct">
      </ProductApprove>
    </div>
  </div>
</template>

<script>

import { dateUTCToLocal, getWebsiteUrl, getFullPathWithScreenSize } from '../../services/workService';
import ProductApprove from './ProductApprove';
import FacebookShare from '../FacebookShare';
import ProfileImage from '../ProfileImage';
import ProductCommentBox from '../productComments/ProductCommentBox';
import { ProductMarkContent } from '../../consts/productMark';
import { ProductStatus } from '../../consts/productStatus';
import { ProductViewType } from '../../consts/productViewType';
import { ProductStatusColor } from '../../consts/productStatusColor';

export default {
  data () {
    return {
      image: null,
      productMarkContent: ProductMarkContent,
      productStatus: ProductStatus,

      content: {
        ru: {
          type: 'Тип',
          description: 'Описание',
          mark: 'Оценка',
          update: 'Обновить',
          delete: 'Удалить',
          rating: 'Рейтинг',
          status: 'Статус',
          created: 'Создан',
          updated: 'Обновлен',
          isApprovedContent: {
            New: 'новый',
            Pending: 'ожидает подтверждения',
            Rejected: 'отклонено',
            Approved: 'подтверждено',
          },
          adminComment: 'Комментарий администратора',
          pendingProductByUser: 'Запрос на публикацию',
        },
        en: {
          type: 'Type',
          description: 'Description',
          mark: 'Mark',
          update: 'Update',
          delete: 'Delete',
          rating: 'Rating',
          status: 'Status',
          created: 'Created',
          updated: 'Updated',
          isApprovedContent: {
            New: 'new',
            Pending: 'pending',
            Rejected: 'rejected',
            Approved: 'approved',
          },
          adminComment: 'Admin comment',
          pendingProductByUser: 'Publication request',
        },
      },
    }
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    productViewType: {
      type: String,
      default: null,
    },
  },
  components: {
    ProductApprove,
    FacebookShare,
    ProductCommentBox,
    ProfileImage,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    productUrl() {
      return `${getWebsiteUrl()}/product-view?productId=${this.product.id}`;
    },
    isPublicList() {
      return this.productViewType === ProductViewType.publicList;
    },
    isPublic() {
      return this.productViewType === ProductViewType.public;
    },
    isAdmin() {
      return this.productViewType === ProductViewType.admin;
    },
    isUser() {
      return this.productViewType === ProductViewType.user;
    },
    productStatusColor() {
      return ProductStatusColor[this.product.status];
    },
  },
  methods: {
    init() {
      if (this.product && this.product.files && this.product.files.length) {
        const file = this.product.files[0];
        this.image = getFullPathWithScreenSize(file.path);
      }
    },
    updateProduct() {
      this.$emit('updateProduct', this.product);
    },
    deleteProduct() {
      this.$emit('deleteProduct', this.product.id);
    },
    like() {
      this.$emit('likeProduct', this.product);
    },
    dislike() {
      this.$emit('dislikeProduct', this.product);
    },
    approveProduct(productApprove) {
      this.$emit('approveProduct', productApprove, this.product.id);
    },
    pendingProductByUser() {
      this.$emit('pendingProductByUser', this.product.id);
    },
    productView() {
      this.$router.push({ path: '/product-view', query: { productId: this.product.id } });
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
    showImage() {
      this.$store.dispatch('setImageModal', this.image);
    },
  }
}
</script>

<style lang="scss" scoped>
.product-block {
  position: relative;
  min-height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  .product-header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .product-body {
    padding-left: 20px;
    padding-right: 20px;
    .product-card {
      margin-top: 10px;
      margin-bottom: 10px;
      .product-name {
        padding: 5px 10px 5px 10px;
        border-radius: 6px;
      }
    }
    .product-image-card {
      text-align: center;
      .product-image {
        border-radius: 6px;
        width: 100%;
        max-width: 300px;
        height: auto;
        cursor: pointer;
      }
    }
  }
  .product-footer {
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid rgba(0,0,0,.125);
    .likes {
      .dislike {
        margin-left: 10px;
      }
      .icon {
        cursor: pointer;
        img {
          width: 16px;
        }
      }
    }
  }
  .product-comments {
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid rgba(0,0,0,.125);
  }
}
</style>
