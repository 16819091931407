const cacheItemsKey = 'cacheItems'; // cacheItems: [] example: [{ key, date, item }]

function removeCacheItem(cacheItemsArray, key) {
  const index = cacheItemsArray.findIndex(x => x.key === key);
  if (index !== -1) {
    cacheItemsArray.splice(index, 1);
  }
  return cacheItemsArray;
}

export const cacheService = {
  setItem(key, item, minutes) {
    let cacheItemsString = localStorage.getItem(cacheItemsKey);
    let cacheItemsArray = [];
    if (cacheItemsString) {
      cacheItemsArray = JSON.parse(cacheItemsString);
      cacheItemsArray = removeCacheItem(cacheItemsArray, key);
    }
    let date = null;
    if (minutes) {
      var dateNow = new Date();
      date = dateNow.setMinutes(dateNow.getMinutes() + minutes);
    }
    cacheItemsArray.push({ key, item, date });
    cacheItemsString = JSON.stringify(cacheItemsArray);
    localStorage.setItem(cacheItemsKey, cacheItemsString);
  },
  getItem(key) {
    let cacheItemsString = localStorage.getItem(cacheItemsKey);
    if (!cacheItemsString) {
      return null;
    }
    let cacheItemsArray = JSON.parse(cacheItemsString);
    const cacheItem = cacheItemsArray.find(x => x.key === key);
    if (!cacheItem) {
      return null;
    } else if (cacheItem.date && new Date().getTime() > cacheItem.date) {
      cacheItemsArray = removeCacheItem(cacheItemsArray, key);
      cacheItemsString = JSON.stringify(cacheItemsArray);
      localStorage.setItem(cacheItemsKey, cacheItemsString);
      return null;
    }
    return cacheItem.item;
  },
};
