<template>
  <div>
    <Spinner :isActive="isLoading"></Spinner>
    <Notification></Notification>
    <ImageModal></ImageModal>
    <ApproveModal></ApproveModal>
    <LanguageModal></LanguageModal>
    <Menu :items="menuItems"></Menu>
    <div class="router-view mb-3">
      <router-view/>
    </div>
  </div>
</template>

<script>

import Spinner from './components/Spinner';
import Notification from './components/Notification';
import ImageModal from './components/modals/ImageModal.vue';
import ApproveModal from './components/modals/ApproveModal.vue';
import LanguageModal from './components/modals/LanguageModal.vue';
import Menu from './components/Menu';
import { MenuItemTypes } from './consts/menuItemTypes';

export default {
  data () {
    return {
      content: {
        ru: {
          login: 'Войти',
          registration: 'Регистрация',
          products: 'Товары',
          about: 'О проекте',
          profile: 'Профиль',
          logout: 'Выйти',
          userProducts: 'Мои товары',
          admin: 'Админка',
        },
        en: {
          login: 'Login',
          registration: 'Registration',
          products: 'Products',
          about: 'About',
          profile: 'Profile',
          logout: 'Logout',
          userProducts: 'My products',
          admin: 'Admin',
        }
      }
    }
  },
  components: {
    Spinner,
    Notification,
    ImageModal,
    ApproveModal,
    Menu,
    LanguageModal,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    menuItems() {
      return [
        {
          type: MenuItemTypes.route,
          hidden: !this.isAuthorized,
          name: this.content[this.lang].profile,
          route: '/profile',
        },
        {
          type: MenuItemTypes.route,
          hidden: !this.isAuthorized,
          name: this.content[this.lang].userProducts,
          route: '/user-products',
        },
        {
          type: MenuItemTypes.route,
          hidden: false,
          name: this.content[this.lang].products,
          route: '/products',
        },
        {
          type: MenuItemTypes.route,
          hidden: false,
          name: this.content[this.lang].about,
          route: '/about',
        },
        {
          type: MenuItemTypes.route,
          hidden: !(this.isAuthorized && this.isAdmin),
          name: this.content[this.lang].admin,
          route: '/admin-profile',
        },
        {
          type: MenuItemTypes.route,
          hidden: this.isAuthorized,
          name: this.content[this.lang].login,
          route: '/login',
        },
        {
          type: MenuItemTypes.route,
          hidden: this.isAuthorized,
          name: this.content[this.lang].registration,
          route: '/registration',
        },
        {
          type: MenuItemTypes.action,
          hidden: false,
          name: this.lang,
          action: () => {
            this.openLanguageModal();
          },
        },
        {
          type: MenuItemTypes.action,
          hidden: !this.isAuthorized,
          name: this.content[this.lang].logout,
          action: () => {
            this.logout();
          },
        },
      ];
    },
  },
  methods: {
    openLanguageModal() {
      this.$store.dispatch('changeLanguageModalActive', true);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
  },
}
</script>

<style lang="scss">

@import 'src/assets/css/global.scss';

.router-view {
  padding-top: 85px;
}
</style>
