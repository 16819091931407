<template>
  <div
    v-if="visible"
    @click.self="clickOutside"
    class="modal-container"
    :class="{ 'modal-visible': isVisible }">
    <div @click="clickInside" class="modal-block box-shadow-default">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      visible: false,
      isVisible: false,
    }
  },
  props: {
    delay: {
      type: Number,
      default: 1,
    },
  },
  computed: {
  },
  methods: {
    show() {
      this.visible = true;
      setTimeout(this.showModalAnimation, this.delay);
    },
    hide() {
      this.visible = false;
      this.isVisible = false;
    },
    showModalAnimation() {
      this.isVisible = true;
    },
    clickOutside() {
      this.hide();
      this.$emit('clickOutside');
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all 200ms ease-out;
  .modal-block {
    position: absolute;
    display:inline-block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    z-index:1000;
    
    @media screen and (max-width: 300px) {
      width: 90%;
      margin: auto;
    }
    @media screen and (min-width: 301px) and (max-width: 767px) {
      width: 70%;
      margin: auto;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      width: 500px;
      margin: auto;
    }
    @media screen and (min-width: 1201px) {
      width: 700px;
      margin: auto;
    }
  }
}
.modal-visible {
  opacity: 1;
}
</style>
