<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="mt-3 text-center">
      <div class="user-image-block">
        <div v-if="userImage" :style="{ 'background-image': 'url(' + userImage + ')' }" class="user-image-card user-image-default"></div>
      </div>
      <div v-if="user && user.userImage" class="mt-3 trash-icon-block">
        <img @click="deleteUserImagePrepare" class="trash-icon" src="@/assets/icons/trash-icon.svg">
      </div>
      <div class="mt-3">
        <ImageLoader
          :addImageText="content[lang].addImageText"
          :uploadReset="true"
          :files="[]"
          @uploadImage="uploadUserImage">
        </ImageLoader>
      </div>
    </div>
    <div v-if="user" class="col-md-6 offset-md-3 mt-3">
      <table class="table table-bordered table-symmetrical mt-4">
        <tbody>
          <tr 
            @click="showUserProducts"
            class="block-hover">
            <td>
              {{ content[lang].products }}
            </td>
            <td>
              <span>{{ user.productsCount }}</span>
            </td>
          </tr>
          <tr 
            v-on="user.subscriptionsCount > 0 ? { click: showSubscriptions } : {}"
            :class="{ 'block-hover': user.subscriptionsCount }">
            <td>
              {{ content[lang].subscriptions }}
            </td>
            <td>
              <span>{{ user.subscriptionsCount }}</span>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].subscribers }}
            </td>
            <td>
              <span>{{ user.subscribersCount }}</span>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].registrationDate }}
            </td>
            <td>
              {{ dateUTCToLocalFunc(user.created) }}
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].loginDate }}
            </td>
            <td>
              {{ dateUTCToLocalFunc(user.loginDate) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center bolder">
        {{ content[lang].personalInfo }}
      </div>
      <div>
        <table class="table table-bordered table-symmetrical mt-3">
          <tbody>
            <tr>
              <td>
                Email
              </td>
              <td>
                {{ user.email }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].name }}
              </td>
              <td>
                {{ user.name }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].age }}
              </td>
              <td>
                <span v-if="user.age">{{ user.age }}</span>
                <span v-else>{{ absent }}</span>
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].gender }}
              </td>
              <td>
                {{ content[lang].genderOptions[user.gender] }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].isMarried }}
              </td>
              <td>
                {{ content[lang].isMarriedTypes[user.isMarried] }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].country }}
              </td>
              <td>
                <span v-if="user.country">
                  <span v-if="lang === 'en'">{{ user.country.name }}</span>
                  <span v-else>{{ user.country.description }}</span>
                </span>
                <span v-else>{{ absent }}</span>
              </td>
            </tr>
            <tr>
              <td>
                {{ content[lang].description }}
              </td>
              <td>
                <span v-if="user.description" class="break-word">{{ user.description }}</span>
                <span v-else class="break-word">{{ absent }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-6 mb-3 text-center">
            <button
              @click="$router.push('/profile-edit')"
              type="button"
              class="btn button-default btn-success">{{ content[lang].editProfile }}
            </button>
          </div>
          <div class="col-md-6 mb-3 text-center">
            <button
              @click="deleteProfilePrepare"
              type="button"
              class="btn button-default btn-danger">{{ content[lang].deleteProfile }}
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <span @click="terms" class="bolder pointer noselect">{{ content[lang].agreementTerms }}</span>
      </div>
      <div class="mt-3">
        <span @click="privacyPolicy" class="bolder pointer noselect">{{ content[lang].privacyPolicy }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { userController } from '../../services/apiService';
import { dateUTCToLocal, getFullPathWithScreenSize, getUserImageDefault } from '../../services/workService';
import ImageLoader from '../../components/ImageLoader';

export default {
  data () {
    return {
      user: null,
      userImage: null,
      absent: '-',

      content: {

        ru: {
          title: 'Профиль',
          name: 'Имя',
          age: 'Возраст',
          gender: 'Пол',
          genderOptions: {
            None: '-',
            Man: 'Мужской',
            Woman: 'Женский',
          },
          isMarried: 'Семейное положение',
          isMarriedTypes: {
            No: 'Не в браке',
            Yes: 'В браке',
          },
          description: 'Описание',
          products: 'Товары',
          subscribers: 'Подписчики',
          subscriptions: 'Подписки',
          registrationDate: 'Дата регистрации',
          loginDate: 'Дата авторизации',
          country: 'Страна',
          editProfile: 'Редактировать',
          deleteProfile: 'Удалить профиль',
          deleteProfileCompleted: 'Профиль был удален',
          personalInfo: 'Личная информация',
          addImageText: 'Изменить',
          deleteUserImage: 'Удаление изображения пользователя',
          userImageChanged: 'Изображение пользователя было обновлено',
          agreementTerms: 'Условия и положения',
          privacyPolicy: 'Политика конфиденциальности',
        },
        en: {
          title: 'Profile',
          name: 'Name',
          age: 'Age',
          gender: 'Gender',
          genderOptions: {
            None: '-',
            Man: 'Man',
            Woman: 'Woman',
          },
          isMarried: 'Family status',
          isMarriedTypes: {
            No: 'Not married',
            Yes: 'Married',
          },
          description: 'Description',
          products: 'Products',
          subscribers: 'Subscribers',
          subscriptions: 'Subscriptions',
          registrationDate: 'Registration date',
          loginDate: 'Authorization date',
          country: 'Country',
          editProfile: 'Edit',
          deleteProfile: 'Delete profile',
          deleteProfileCompleted: 'Profile was removed',
          personalInfo: 'Personal info',
          addImageText: 'Change',
          deleteUserImage: 'Removing user image',
          userImageChanged: 'User image was changed',
          agreementTerms: 'Terms and conditions',
          privacyPolicy: 'Privacy policy',
        },
      },
    }
  },
  components: {
    ImageLoader,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.setUser();
    },
    setUser() {
      userController.getUserHttpGet()
        .then((data) => {
          this.user = data;
          this.setUserImage();
        });
    },
    uploadUserImage(formData) {
      userController.addUserImageHttpPost(formData)
        .then((data) => {
          this.user.userImage = data.userImage;
          this.setUserImage();
          const notification = {
            content: this.content[this.lang].userImageChanged,
          };
          this.$store.dispatch('setNotification', notification);
        });
    },
    deleteUserImagePrepare() {
      const approveModalItem = {
        content: this.content[this.lang].deleteUserImage,
        approveAction: () => { this.deleteUserImage() },
        cancelAction: null,
      };
      this.$store.dispatch('setApproveModal', approveModalItem);
    },
    deleteUserImage() {
      userController.deleteUserImageHttpDelete()
        .then(() => {
          this.user.userImage = null;
          this.setUserImage();
          const notification = {
            content: this.content[this.lang].userImageChanged,
          };
          this.$store.dispatch('setNotification', notification);
        });
    },
    showUserProducts() {
      this.$router.push('/user-products');
    },
    showSubscriptions() {
      this.$router.push('/subscriptions');
    },
    setUserImage() {
      if (this.user && this.user.userImage) {
        this.userImage = getFullPathWithScreenSize(this.user.userImage);
      } else {
        this.userImage = getUserImageDefault();
      }
      this.getUser.userImage = this.userImage;
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
    terms() {
      this.$router.push('/terms');
    },
    privacyPolicy() {
      this.$router.push('/privacy-policy');
    },
    deleteProfilePrepare() {
      const approveModalItem = {
        content: this.content[this.lang].deleteProfile,
        approveAction: () => { this.deleteProfile() },
        cancelAction: null,
      };
      this.$store.dispatch('setApproveModal', approveModalItem);
    },
    deleteProfile() {
      userController.deleteUserAccountHttpDelete()
        .then(() => {
          this.user = null;
          const notification = {
            content: this.content[this.lang].deleteProfileCompleted,
          };
          this.$store.dispatch('setNotification', notification);
          this.$store.dispatch('logout');
          this.$router.push('/');
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.user-image-block {
  .user-image-card {
    display: inline-block;
    width: 150px;
    height: 150px;
  }
}
.trash-icon-block {
  display: inline-block;
  width: 20px;
  .trash-icon {
    cursor: pointer;
    width: 100%;
  }
}
.table-symmetrical {
  td {
    width: 50%;
  }
}
</style>
