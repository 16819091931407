<template>
  <div>
    <div class="col-md-8 offset-md-2">
      <div class="text-center bolder font-size-20 mt-3">
        {{ content[lang].title }}
      </div>
      <div v-if="isAuthorized" class="text-center mt-3">
        <button
          @click="$router.push('/products')"
          type="button"
          class="btn button-default btn-success">{{ content[lang].products }}
        </button>
      </div>
      <div v-else class="text-center mt-3">
        <button
          @click="$router.push('/registration')"
          type="button"
          class="btn button-default btn-success">{{ content[lang].registration }}
        </button>
      </div>
      <div
        v-if="isFirstLoadCompleted"
        class="cards col-md-12 mt-4"
        :class="{ 'cards-active': isCardsActive }">
        <div class="row">
          <div class="col-md-4 mb-4">
            <HomeCard
              class="height-full"
              icon="feather.svg"
              :header="content[lang].cardFirstHeader"
              :content="content[lang].cardFirstContent">
            </HomeCard>
          </div>
          <div class="col-md-4 mb-4">
            <HomeCard
              class="height-full"
              icon="rocket.svg"
              :header="content[lang].cardSecondHeader"
              :content="content[lang].cardSecondContent">
            </HomeCard>
          </div>
          <div class="col-md-4 mb-4">
            <HomeCard
              class="height-full"
              icon="check.svg"
              :header="content[lang].cardThirdHeader"
              :content="content[lang].cardThirdContent">
            </HomeCard>
          </div>
        </div>
        <div class="text-center">
          <span @click="sendEmail(email)" class="bolder noselect pointer">{{ email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HomeCard from '../../components/HomeCard';

export default {
  data () {
    return {
      email: 'productmixik@gmail.com',
      isCardsActive: false,

      content: {
        ru: {
          title: 'ProductMix - платформа для независимых мнений о товарах и услугах',
          registration: 'Регистрация',
          products: 'Товары',
          cardFirstHeader: 'Ничего лишнего',
          cardFirstContent: 'Простой и интуитивно-понятный интерфейс для пользователя',
          cardSecondHeader: 'Удобство и скорость',
          cardSecondContent: 'Быстрая работа приложения на любых устройствах',
          cardThirdHeader: 'Качество контента',
          cardThirdContent: 'Проверка информации и фильтр некорректного контента',
        },
        en: {
          title: 'ProductMix it\'s a platform for independent opinions about goods and services',
          registration: 'Registration',
          products: 'Products',
          cardFirstHeader: 'Nothing extra',
          cardFirstContent: 'Simple and intuitive interface for user',
          cardSecondHeader: 'Convenience and speed',
          cardSecondContent: 'Light work of the application on any device',
          cardThirdHeader: 'Content quality',
          cardThirdContent: 'Checking information and filtering incorrect content',
        },
      },
    }
  },
  components: {
    HomeCard,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    isFirstLoadCompleted() {
      return this.$store.getters.isFirstLoadCompleted;
    },
  },
  created() {
    this.initCreated();
  },
  mounted() {
    this.initMounted();
  },
  methods: {
    initCreated() {
      if (this.isFirstLoadCompleted) {
        this.activeCards();
      }
    },
    initMounted() {
      if (!this.isFirstLoadCompleted) {
        this.showHomeCards();
      }
    },
    showHomeCards() {
      this.$store.dispatch('setFirstLoadCompleted', true);
      setTimeout(this.activeCards, 1);
    },
    activeCards() {
      this.isCardsActive = true;
    },
    sendEmail(email) {
      window.location.href = `mailto:${email}`;
    },
  },
}
</script>

<style scoped>
.height-full {
  height: 100%;
}
.cards {
  opacity: 0;
  transition: all 2000ms ease-out;
}
.cards-active {
  opacity: 1;
}
</style>
