<template>
  <div class="product-block">
    <InputBox
      :modelValue="name"
      @update:modelValue="name = $event"
      :content="name"
      :title="content[lang].name"
      type="text"
      :isError="nameError"
      :messageError="content[lang].required">
    </InputBox>
    <SelectBox
      class="mb-3"
      :modelValue="productTypeId"
      @update:modelValue="productTypeId = $event"
      :model="productTypeId"
      :label="content[lang].type"
      :defaultOption="false"
      :options="productTypesOptions"
      :isError="productTypeIdError"
      :messageError="content[lang].required">
    </SelectBox>
    <InputBox
      :modelValue="description"
      @update:modelValue="description = $event"
      :content="description"
      :title="content[lang].description"
      type="text"
      :isError="descriptionError"
      :isTextarea="true"
      :messageError="content[lang].required">
    </InputBox>
    <SelectBox
      class="mb-3"
      :modelValue="mark"
      @update:modelValue="mark = $event"
      defaultOptionValue="Good"
      :model="mark"
      :label="content[lang].mark"
      :options="content[lang].productMarkOptions">
    </SelectBox>
    <div class="text-center">
      <ImageLoader
        ref="ImageLoader"
        :files="product ? product.files : null"
        @uploadImage="uploadImage"
        @deleteCurrentFile="deleteCurrentFile"
        @setImageError="setImageError"
        :uploadHidden="true">
      </ImageLoader>
    </div>
    <div class="pt-3 pb-3 text-center">
      <button
        @click="submit"
        :disabled="submitDisabled"
        type="button"
        class="btn button-default btn-success">{{ content[lang].save }}
      </button>
    </div>
  </div>
</template>

<script>

import InputBox from '../InputBox';
import SelectBox from '../SelectBox';
import ImageLoader from '../ImageLoader';
import { ProductMarkContent, ProductMark } from '../../consts/productMark';

export default {
  data () {
    return {
      name: '',
      description: '',
      productTypeId: null,
      mark: 'Good',
      image: null,
      imageError: false,

      content: {
        ru: {
          required: 'Требуется заполнить',
          name: 'Название',
          description: 'Описание',
          type: 'Тип',
          mark: 'Оценка',
          save: 'Сохранить',
          productMarkOptions: [
            {
              text: ProductMarkContent.ru.Bad,
              value: ProductMark.Bad,
            },
            {
              text: ProductMarkContent.ru.Normal,
              value: ProductMark.Normal,
            },
            {
              text: ProductMarkContent.ru.Good,
              value: ProductMark.Good,
            },
            {
              text: ProductMarkContent.ru.Perfect,
              value: ProductMark.Perfect,
            },
          ],
        },
        en: {
          required: 'Field is required',
          name: 'Name',
          description: 'Description',
          type: 'Type',
          mark: 'Mark',
          save: 'Save',
          productMarkOptions: [
            {
              text: ProductMarkContent.en.Bad,
              value: ProductMark.Bad,
            },
            {
              text: ProductMarkContent.en.Normal,
              value: ProductMark.Normal,
            },
            {
              text: ProductMarkContent.en.Good,
              value: ProductMark.Good,
            },
            {
              text: ProductMarkContent.en.Perfect,
              value: ProductMark.Perfect,
            },
          ],
        },
      },
    }
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    productTypes: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    InputBox,
    SelectBox,
    ImageLoader,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    productTypesOptions() {
      let productTypesOptions = [];
      if (this.productTypes.length) {
        const isName = this.lang === 'en';
        this.productTypes.forEach((type) => {
          productTypesOptions.push({
            text: isName ? type.name : type.description,
            value: type.id,
          });
        });
      }
      return productTypesOptions;
    },
    nameError() {
      return !this.name.length;
    },
    productTypeIdError() {
      return !this.productTypeId;
    },
    descriptionError() {
      return !this.description.length;
    },
    submitDisabled() {
      if (this.nameError || this.descriptionError || this.productTypeIdError || this.imageError) {
        return true;
      }
      return false;
    },
  },
  watch: {
    product() {
      this.init();
    },
  },
  methods: {
    init() {
      if (!this.product) return;
      this.name = this.product.name;
      this.productTypeId = this.product.productType.id;
      this.description = this.product.description;
      this.mark = this.product.mark;
    },
    submit() {
      let product = {
        name: this.name,
        productTypeId: this.productTypeId,
        description: this.description,
        mark: this.mark,
      };
      product.formFile = this.$refs.ImageLoader.getImageFormData();
      this.$emit('submit', product);
    },
    uploadImage(formData) {
      this.$emit('uploadImage', formData);
    },
    setImageError(imageError) {
      this.imageError = imageError;
    },
    deleteCurrentFile(fileId) {
      this.$emit('deleteCurrentFile', fileId);
    },
    clear() {
      this.name = '';
      this.description = '';
      this.productTypeId = null;
      this.mark = 'Good';
      this.image = null;
      this.$refs.ImageLoader.closeFile();
    },
  },
}
</script>

<style lang="scss" scoped>
.product-block {
  position: relative;
}
</style>
