<template>
  <div class="profile-image-header">
    <div
      @click="profileView(userId)"
      :style="{ 'background-image': 'url(' + userImage + ')' }"
      :class="{ 'profile-image-min-size': isMinSize }"
      class="user-image-default user-image-card-defult pointer"></div>
    <div v-if="userName" class="user-name-block">
      <span @click="profileView(userId)" class="user-name">{{ userName }}</span>
    </div>
  </div>
</template>

<script>

import { getFullPathWithScreenSize, getUserImageDefault } from '../services/workService';

export default {
  data () {
    return {
      userImage: null,
    }
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    userName: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    isMinSize: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  created() {
    this.setUserImage();
  },
  methods: {
    setUserImage() {
      if (this.image) {
        this.userImage = getFullPathWithScreenSize(this.image);
      } else {
        this.userImage = getUserImageDefault();
      }
    },
    profileView(userId) {
      this.$router.push({ path: '/profile-view', query: { userId } });
    },
  }
}
</script>

<style lang="scss" scoped>
.profile-image-header {
  display: flex;
  .profile-image-min-size {
    width: 70px !important;
    height: 70px !important;
  }
  .user-name-block {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .user-name {
      font-weight: bolder;
      cursor: pointer;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .user-image-card-defult {
    @media screen and (max-width: 767px) {
      width: 70px;
      height: 70px;
    }
    @media screen and (min-width: 768px) and (max-width: 1200px) {
      width: 90px;
      height: 90px;
    }
    @media screen and (min-width: 1201px) {
      width: 110px;
      height: 110px;
    }
  }
}
</style>
