import { createStore } from 'vuex';

const TOKEN_ACCESS_KEY = 'token_access';
const TOKEN_REFRESH_KEY = 'token_refresh';
const USER_KEY = 'user';
const LANGUAGE_KEY = 'language';

export default createStore({
  state: {
    language: 'en',
    languageModalActive: false,
    isLoading: false,
    firstLoadCompleted: false,
    notification: null,
    imageModal: null,
    approveModal: null,
    tokenAccess: null,
    tokenRefresh: null,
    user: null,
  },
  getters: {
    getLanguage: state => { return state.language; },
    getLanguageModalActive: state => { return state.languageModalActive; },
    getTokenAccess: state => { return state.tokenAccess; },
    getTokenRefresh: state => { return state.tokenRefresh; },
    isAuthorized: state => { return state.tokenAccess !== null; },
    isLoading: state => { return state.isLoading; },
    isFirstLoadCompleted: state => { return state.firstLoadCompleted; },
    getNotification: state => { return state.notification },
    getImageModal: state => { return state.imageModal },
    getApproveModal: state => { return state.approveModal },
    isAdmin: state => { return state.user && state.user.roles && state.user.roles.includes('Admin') },
    getUser: state => {
      if (state.user) {
        return state.user;
      }
      const userString = localStorage.getItem(USER_KEY);
      if (userString) {
        state.user = JSON.parse(userString);
      }
      return state.user;
    },
  },
  actions: {
    changeLanguage ({ commit }, data) {
      localStorage.setItem(LANGUAGE_KEY, data);
      commit('changeLanguage', data);
    },
    changeLanguageModalActive ({ commit }, data) {
      commit('changeLanguageModalActive', data);
    },
    login ({ commit }, data) {
      localStorage.setItem(TOKEN_ACCESS_KEY, data.tokenAccess);
      localStorage.setItem(TOKEN_REFRESH_KEY, data.tokenRefresh);
      commit('login', data);
    },
    logout ({ commit }) {
      localStorage.removeItem(TOKEN_ACCESS_KEY);
      localStorage.removeItem(TOKEN_REFRESH_KEY);
      localStorage.removeItem(USER_KEY);
      commit('logout');
    },
    readToken({ commit }) {
      let tokenAccess = '';
      let tokenRefresh = '';
      let user = null;
      let language = null;
      try {
        tokenAccess = localStorage.getItem(TOKEN_ACCESS_KEY);
        tokenRefresh = localStorage.getItem(TOKEN_REFRESH_KEY);
        user = JSON.parse(localStorage.getItem(USER_KEY));
        language = localStorage.getItem(LANGUAGE_KEY);
      } catch (e) { return; }
      if (tokenAccess && tokenRefresh) {
        commit('login', { tokenAccess, tokenRefresh });
      }
      if (user) {
        commit('setUser', user);
      }
      if (language) {
        commit('changeLanguage', language);
      }
    },
    removeTokenAccess({ commit }) {
      localStorage.removeItem(TOKEN_ACCESS_KEY);
      commit('removeTokenAccess');
    },
    setLoading ({ commit }, data) {
      commit('setLoading', data);
    },
    setFirstLoadCompleted ({ commit }, data) {
      commit('setFirstLoadCompleted', data);
    },
    setNotification ({ commit }, data) {
      commit('setNotification', data);
    },
    setImageModal ({ commit }, data) {
      commit('setImageModal', data);
    },
    setApproveModal ({ commit }, data) {
      commit('setApproveModal', data);
    },
    setUser ({ commit }, data) {
      localStorage.setItem(USER_KEY, JSON.stringify(data));
      commit('setUser', data);
    },
  },
  mutations: {
    changeLanguage (state, data) {
      state.language = data;
    },
    changeLanguageModalActive (state, data) {
      state.languageModalActive = data;
    },
    login (state, data) {
      state.tokenAccess = data.tokenAccess;
      state.tokenRefresh = data.tokenRefresh;
    },
    logout (state) {
      state.tokenAccess = null;
      state.tokenRefresh = null;
      state.user = null;
    },
    removeTokenAccess (state) {
      state.tokenAccess = null;
    },
    setLoading (state, data) {
      state.isLoading = data;
    },
    setFirstLoadCompleted (state, data) {
      state.firstLoadCompleted = data;
    },
    setNotification (state, data) {
      state.notification = data;
    },
    setImageModal (state, data) {
      state.imageModal = data;
    },
    setApproveModal (state, data) {
      state.approveModal = data;
    },
    setUser (state, data) {
      state.user = data;
    },
  },
})
