export const ProductMark = {
  Bad: 'Bad',
  Normal: 'Normal',
  Good: 'Good',
  Perfect: 'Perfect',
}

export const ProductMarkContent = {
  ru: {
    Bad: 'Плохо',
    Normal: 'Нормально',
    Good: 'Хорошо',
    Perfect: 'Отлично',
  },
  en: {
    Bad: 'Bad',
    Normal: 'Normal',
    Good: 'Good',
    Perfect: 'Perfect',
  }
}
