<template>
  <div>
    <img @click="showNotification" class="info-icon" :src="getIconPath(icon)">
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    getIconPath (iconName) {
      return require(`@/assets/icons/${iconName}`);
    },
    showNotification() {
      const notification = {
        content: this.content,
      };
      this.$store.dispatch('setNotification', notification);
    },
  },
}
</script>

<style lang="scss" scoped>
.info-icon {
  width: 20px;
  cursor: pointer;
}
</style>
