<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3 pt-3">
      <div class="mb-3">
        <SearchBoxProduct
          @searchEvent="searchEvent"
          :productTypes="productTypes">
        </SearchBoxProduct>
      </div>
      <div
        v-for="(productCard, index) in productCards" :key="index">
        <Product
          v-if="productCard.type === productCardTypes.product"
          :product="productCard.item"
          @likeProduct="likeProduct"
          @dislikeProduct="dislikeProduct"
          :productViewType="productViewTypes.publicList">
        </Product>
        <AdvertBox
          v-else-if="advertisements.length"
          :item="advertisementRandom">
        </AdvertBox>
      </div>
    </div>
  </div>
</template>

<script>

import Product from '../../components/products/Product';
import { productController } from '../../services/apiService';
import SearchBoxProduct from '../../components/products/SearchBoxProduct';
import { ProductCardTypes } from '../../consts/productCardTypes';
import AdvertBox from '../../components/AdvertBox';
import { advertisementController } from '../../services/apiServiceAdvertisement';
import { ProductViewType } from '../../consts/productViewType';

export default {
  data () {
    return {
      products: [],
      productQuery: {
        userId: null,
        name: null,
        dateFrom: null,
        dateTo: null,
        skip: null,
        take: 10,
        IsAscending: false,
        userName: null,
      },
      productTypes: [],
      isScrollSearchDisabled: false,
      advertisements: [],
      advertInterval: 1,
      advertDisabled: true,
      productViewTypes: ProductViewType,

      content: {
        ru: {
          title: 'Товары',
          needAuthorize: 'Нужно войти в аккаунт',
        },
        en: {
          title: 'Products',
          needAuthorize: 'Need to login in account',
        },
      },
    }
  },
  components: {
    Product,
    SearchBoxProduct,
    AdvertBox,
  },
  async created() {
    this.initQueryParams();
    this.initProducts();
    this.initAvertisements();
    await this.setProductTypes();
    window.addEventListener('scroll', this.scrollAction);
  },
  unmounted () {
    window.removeEventListener('scroll', this.scrollAction);
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    productsSkip() {
      return this.products.length;
    },
    productCards() {
      let productCards = [];
      if (this.advertDisabled) {
        for (let i = 0; i < this.products.length; i++) {
          productCards.push({
            item: this.products[i],
            type: ProductCardTypes.product,
          });
        }
        return productCards;
      }
      let count = 0;
      for (let i = 0; i < this.products.length; i++) {
        if (count === this.advertInterval) {
          productCards.push({ type: ProductCardTypes.advert });
          count = 0;
        }
        productCards.push({
          item: this.products[i],
          type: ProductCardTypes.product,
        });
        count++;
      }
      return productCards;
    },
    productCardTypes() {
      return ProductCardTypes;
    },
    advertisementRandom() {
      if (!this.advertisements.length) return null;
      return this.advertisements[Math.floor(Math.random() * this.advertisements.length)];
    },
  },
  methods: {
    initQueryParams() {
      this.productQuery.name = this.$route.query.name;
      this.productQuery.userName = this.$route.query.userName;
      this.productQuery.productTypeId = this.$route.query.productTypeId;
    },
    initProducts() {
      if (this.isScrollSearchDisabled) return;
      this.initProductsTimer();
      this.productQuery.skip = this.productsSkip;
      productController.getProductsPublicHttpPost(this.productQuery)
        .then((data) => {
          data.forEach(item => {
            this.products.push(item);
          });
        });
    },
    initAvertisements() {
      if (this.advertDisabled) return;
      advertisementController.getAdvertisementListPublicCacheHttpGet()
        .then((data) => {
          this.advertisements = data;
        });
    },
    async setProductTypes() {
      this.productTypes = await productController.getProductTypesCacheHttpGet();
    },
    scrollAction () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.initProducts();
      }
    },
    initProductsTimer() {
      this.isScrollSearchDisabled = true;
      let context = this;
      setTimeout(function() { context.isScrollSearchDisabled = false }, 1000);
    },
    likeProduct(product) {
      this.likeProductBase(product, true);
    },
    dislikeProduct(product) {
      this.likeProductBase(product, false);
    },
    likeProductBase(product, isLike) {
      if (!this.isAuthorized) {
        this.$store.dispatch('setNotification', { content: this.content[this.lang].needAuthorize });
        return;
      }
      productController.likeProductHttpPost(product.id, isLike)
        .then((data) => {
          for (let i = 0; i < this.products.length; i++) {
            let product = this.products[i];
            if (product.id === data.id) {
              product.likes = data.likes;
              product.dislikes = data.dislikes;
              product.userContextIsLike = data.userContextIsLike;
              break;
            }
          }
        });
    },
    searchEvent(productQuery) {
      this.productQuery.name = productQuery.name;
      this.productQuery.userName = productQuery.userName;
      this.productQuery.productTypeId = productQuery.productTypeId;
      this.products = [];
      this.initProducts();
    },
  },
}
</script>

<style scoped>
.close-icon {
  width: 20px;
  top: 7px;
  right: 12px;
  cursor: pointer;
}
</style>
