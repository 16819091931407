export const ErrorsContent = {
  ru: {
    MODEL_IS_INVALID: 'Данные некорректны',
    USER_EMAIL_ALREADY_EXISTS: 'Email уже существует',
    USER_NAME_ALREADY_EXISTS: 'Имя уже существует',
    USER_NOT_FOUND: 'Пользователь не найден',
    USER_NOT_ACTIVE: 'Аккаунт не активирован',
    USER_BLOCKED_BY_ADMIN: 'Аккаунт заблокирован администратором',
    USER_SECURITY_CODE_INVALID: 'Код некорректный',
    DATA_NOT_FOUND: 'Данные не найдены',
    FILES_NOT_FOUND: 'Файлы не найдены',
    FOLDER_NOT_FOUND: 'Папка не найдена',
    DISALLOWED_WITH_YOUR_ITEM: 'Недоступно для данного элемента',
    ITEM_ALREADY_EXISTS: 'Элемент уже существует',
    DISALLOWED_WITH_CURRENT_STATUS: 'Недоступно для текущего статуса',
    PLEASE_TRY_LATER: 'Пожалуйста, попробуйте позже',
    FILE_TYPE_IS_INVALID: 'Формат файла недопустим',
  },
  en: {
    MODEL_IS_INVALID: 'The data is incorrect',
    USER_EMAIL_ALREADY_EXISTS: 'Email already exists',
    USER_NAME_ALREADY_EXISTS: 'Name already exists',
    USER_NOT_FOUND: 'User not found',
    USER_NOT_ACTIVE: 'Account not activated',
    USER_BLOCKED_BY_ADMIN: 'The account has been blocked by the administrator',
    USER_SECURITY_CODE_INVALID: 'The code is incorrect',
    DATA_NOT_FOUND: 'Data not found',
    FILES_NOT_FOUND: 'Files not found',
    FOLDER_NOT_FOUND: 'Folder not found',
    DISALLOWED_WITH_YOUR_ITEM: 'Not available for this item',
    ITEM_ALREADY_EXISTS: 'Element already exists',
    DISALLOWED_WITH_CURRENT_STATUS: 'Not available for current status',
    PLEASE_TRY_LATER: 'Please try again later',
    FILE_TYPE_IS_INVALID: 'File format is invalid',
  },
}
export const ErrorCodes = {
  MODEL_IS_INVALID: 'MODEL_IS_INVALID',
  USER_EMAIL_ALREADY_EXISTS: 'USER_EMAIL_ALREADY_EXISTS',
  USER_NAME_ALREADY_EXISTS: 'USER_NAME_ALREADY_EXISTS',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_NOT_ACTIVE: 'USER_NOT_ACTIVE',
  USER_BLOCKED_BY_ADMIN: 'USER_BLOCKED_BY_ADMIN',
  USER_SECURITY_CODE_INVALID: 'USER_SECURITY_CODE_INVALID',
  DATA_NOT_FOUND: 'DATA_NOT_FOUND',
  FILES_NOT_FOUND: 'FILES_NOT_FOUND',
  FOLDER_NOT_FOUND: 'FOLDER_NOT_FOUND',
  DISALLOWED_WITH_YOUR_ITEM: 'DISALLOWED_WITH_YOUR_ITEM',
  ITEM_ALREADY_EXISTS: 'ITEM_ALREADY_EXISTS',
  DISALLOWED_WITH_CURRENT_STATUS: 'DISALLOWED_WITH_CURRENT_STATUS',
  PLEASE_TRY_LATER: 'PLEASE_TRY_LATER',
  FILE_TYPE_IS_INVALID: 'FILE_TYPE_IS_INVALID',
}
