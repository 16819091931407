<template>
  <div>
    <nav id="navbar-block" class="navbar navbar-expand-md navbar-dark background-color-brand box-shadow-default">
      <span @click="titleEvent" class="navbar-brand pointer">
        <img class="logo" src="@/assets/icons/feather-logo-white.svg">
        <span class="title">ProductMix</span>
      </span>
      <button
        class="navbar-toggler"
        type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/products" class="nav-link">{{ content[lang].products }}</router-link>
          </li>
          <li v-if="isAuthorized" class="nav-item">
            <router-link to="/user-products" class="nav-link">{{ content[lang].userProducts }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">{{ content[lang].about }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav nav-right">
          <li class="nav-item">
            <a @click="openLanguageModal" class="nav-link">{{ lang }}</a>
          </li>
          <li v-if="isAdmin" class="nav-item">
            <router-link to="/admin-profile" class="nav-link">{{ content[lang].admin }}</router-link>
          </li>
          <li v-if="!isAuthorized" class="nav-item">
            <router-link to="/login" class="nav-link">{{ content[lang].login }}</router-link>
          </li>
          <li v-if="!isAuthorized" class="nav-item">
            <router-link to="/registration" class="nav-link">{{ content[lang].registration }}</router-link>
          </li>
          <li v-if="isAuthorized" class="nav-item">
            <router-link to="/profile" class="nav-link">{{ content[lang].profile }}</router-link>
          </li>
          <li v-if="isAuthorized" class="nav-item">
            <a @click="logout" class="nav-link">{{ content[lang].logout }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  data () {
    return {
      content: {
        ru: {
          login: 'Войти',
          registration: 'Регистрация',
          products: 'Товары',
          about: 'О проекте',
          profile: 'Профиль',
          logout: 'Выйти',
          userProducts: 'Мои товары',
          admin: 'Админка',
        },
        en: {
          login: 'Login',
          registration: 'Registration',
          products: 'Products',
          about: 'About',
          profile: 'Profile',
          logout: 'Logout',
          userProducts: 'My products',
          admin: 'Admin',
        }
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    openLanguageModal() {
      this.$store.dispatch('changeLanguageModalActive', true);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    titleEvent() {
      this.$router.push('/');
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  position: fixed !important;
  z-index: 1000;
  padding: 8px 16px 8px 16px;
}
.navbar-collapse {
  margin-left: 10px;
}
.nav-item {
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.nav-right {
  margin-left: auto;
}
.nav-link {
  font-weight: 600;
  color: #FFFFFF !important;
  &:hover {
    color: #FFFFFFD6 !important;
  }
}
.logo {
  width: 35px;
}
.title {
  margin-left: 6px;
}
</style>
