import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { RouteTypes } from '../consts/routeTypes';

import Home from '@/views/home/Home';
import About from '@/views/about/About';
import Products from '@/views/products/Products';
import Registration from '@/views/registration/Registration';
import Login from '@/views/login/Login';
import Activation from '@/views/activation/Activation';
import PasswordChange from '@/views/passwordChange/PasswordChange';
import Profile from '@/views/profile/Profile';
import ProfileView from '@/views/profileView/ProfileView';
import ProfileEdit from '@/views/profileEdit/ProfileEdit';
import UserProducts from '@/views/userProducts/UserProducts';
import ProductForm from '@/views/productForm/ProductForm';
import Subscriptions from '@/views/subscriptions/Subscriptions';
import ProductView from '@/views/productView/ProductView';

// Lazy Loaded components
const NotFound = () => import('@/views/notFound/NotFound');
const Terms = () => import('@/views/terms/Terms');
const PrivacyPolicy = () => import('@/views/privacyPolicy/PrivacyPolicy');
const AdminProfile = () => import('@/views/admin/AdminProfile');
const AdminProductsApprove = () => import('@/views/admin/AdminProductsApprove');
const AdminUserList = () => import('@/views/admin/AdminUserList');

const router = createRouter({
  history: process.env.VUE_APP_TYPE === 'cordova' ? createWebHashHistory() : createWebHistory(),
  routes: [
    { path: '/', name: 'Home', component: Home, meta: { type: RouteTypes.default } },
    { path: '/*', name: 'NotFound', component: NotFound, meta: { type: RouteTypes.default } },
    { path: '/about', name: 'About', component: About, meta: { type: RouteTypes.default } },
    { path: '/terms', name: 'Terms', component: Terms, meta: { type: RouteTypes.default } },
    { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy, meta: { type: RouteTypes.default } },
    { path: '/products', name: 'Products', component: Products, meta: { type: RouteTypes.default } },
    { path: '/product-view', name: 'ProductView', component: ProductView, meta: { type: RouteTypes.default } },
    { path: '/registration', name: 'Registration', component: Registration, meta: { type: RouteTypes.unauthorized } },
    { path: '/login', name: 'Login', component: Login, meta: { type: RouteTypes.unauthorized } },
    { path: '/activation', name: 'Activation', component: Activation, meta: { type: RouteTypes.unauthorized } },
    { path: '/password-change', name: 'PasswordChange', component: PasswordChange, meta: { type: RouteTypes.default } },
    { path: '/profile', name: 'Profile', component: Profile, meta: { type: RouteTypes.authorized } },
    { path: '/profile-view', name: 'ProfileView', component: ProfileView, meta: { type: RouteTypes.default } },
    { path: '/profile-edit', name: 'ProfileEdit', component: ProfileEdit, meta: { type: RouteTypes.authorized } },
    { path: '/user-products', name: 'UserProducts', component: UserProducts, meta: { type: RouteTypes.authorized } },
    { path: '/product-form', name: 'ProductForm', component: ProductForm, meta: { type: RouteTypes.authorized } },
    { path: '/subscriptions', name: 'Subscriptions', component: Subscriptions, meta: { type: RouteTypes.authorized } },
    { path: '/admin-profile', name: 'AdminProfile', component: AdminProfile, meta: { type: RouteTypes.admin } },
    { path: '/admin-products-approve', name: 'AdminProductsApprove', component: AdminProductsApprove, meta: { type: RouteTypes.admin } },
    { path: '/admin-user-list', name: 'AdminUserList', component: AdminUserList, meta: { type: RouteTypes.admin } },
  ]
});

export default router;
