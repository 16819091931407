<template>
  <button
    v-if="url"
    @click="share"
    type="button"
    class="btn facebook-button">
    <span class="facebook-icon"><img src="@/assets/icons/social/facebook.svg"></span>
  </button>
</template>

<script>
export default {
  data () {
    return {
      facebookBaseUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
    }
  },
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  methods: {
    share() {
      window.open(this.facebookBaseUrl + this.url, '_blank');
    },
  },
}
</script>

<style scoped lang="scss">
.facebook-button {
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  height: 28px;
  padding: 0 6px 0 6px;
  font-family: Helvetica, Arial, sans-serif;
  background: #4267b2;
  border: 1px solid #4267b2;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  position: relative;
  -webkit-user-select: none;
  white-space: nowrap;
  font-weight: bold;
  .facebook-icon {
    position: relative;
    top: -1px;
    img {
      width: 16px;
    }
  }
}
</style>
