<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3 pt-3">
      <div class="text-center">
        <button
          @click="$router.push('/product-form')"
          type="button"
          class="btn button-default btn-success">{{ content[lang].createProduct }}
        </button>
      </div>
      <div class="mt-3">
        <div
          v-for="(item, index) in userProducts" :key="index"
          class="product">
          <Product
            @updateProduct="updateProduct"
            @deleteProduct="deleteProductPrepare"
            @likeProduct="likeProduct"
            @dislikeProduct="dislikeProduct"
            @pendingProductByUser="pendingProductByUserPrepare"
            :product="item"
            :productViewType="productViewTypes.user">
          </Product>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Product from '../../components/products/Product';
import { productController } from '../../services/apiService';
import { ProductViewType } from '../../consts/productViewType';

export default {
  data () {
    return {
      userProducts: null,
      productQuery: {
        userId: null,
        name: null,
        dateFrom: null,
        dateTo: null,
        skip: null,
        take: null,
        IsAscending: false,
        userName: null, // disallow to use this filter for current user products
      },
      productViewTypes: ProductViewType,

      content: {
        ru: {
          title: 'Мои товары',
          createProduct: 'Создать товар',
          deleteProductApprove: 'Удаление товара',
          needAuthorize: 'Нужно войти в аккаунт',
          pendingProductByUser: 'После подтверждения продукта - он будет недоступен к редактированию',
        },
        en: {
          title: 'My products',
          createProduct: 'Create product',
          deleteProductApprove: 'Removing product',
          needAuthorize: 'Need to login in account',
          pendingProductByUser: 'After confirming the product - it will not be available for editing',
        },
      },
    }
  },
  components: {
    Product,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    productsSkip() {
      return this.products.length;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
  },
  methods: {
    init() {
      this.getProducts();
    },
    getProducts() {
      productController.getProductsHttpPost(this.productQuery)
        .then((data) => {
          this.userProducts = data;
        });
    },
    updateProduct(product) {
      this.$router.push({ path: '/product-form', query: { productId: product.id } });
    },
    deleteProductPrepare(productId) {
      const approveModalItem = {
        content: this.content[this.lang].deleteProductApprove,
        approveAction: () => { this.deleteProduct(productId) },
        cancelAction: null,
      };
      this.$store.dispatch('setApproveModal', approveModalItem);
    },
    deleteProduct(productId) {
      productController.deleteProductHttpDelete(productId)
        .then(() => {
          this.deleteProductById(productId);
        });
    },
    deleteProductById(productId) {
      let index = 0;
      for (let i = 0; i < this.userProducts.length; i++) {
        if (this.userProducts[i].id === productId) {
          index = i;
          break;
        }
      }
      this.userProducts.splice(index, 1);
      const userProductsTemp = this.userProducts;
      this.userProducts = [];
      const context = this;
      setTimeout(() => { context.userProducts = userProductsTemp; }, 1); // refresh userProducts - feature for fixing bug with disappearing image
    },
    pendingProductByUserPrepare(productId) {
      const approveRequestModalItem = {
        content: this.content[this.lang].pendingProductByUser,
        approveAction: () => { this.pendingProductByUser(productId) },
        cancelAction: null,
      };
      this.$store.dispatch('setApproveModal', approveRequestModalItem);
    },
    pendingProductByUser(productId) {
      productController.pendingProductByUserHttpPatch(productId)
        .then((data) => {
          for (let i = 0; i < this.userProducts.length; i++) {
            if (this.userProducts[i].id === productId) {
              this.userProducts[i].status = data.status;
              break;
            }
          }
        });
    },
    likeProduct(product) {
      this.likeProductBase(product, true);
    },
    dislikeProduct(product) {
      this.likeProductBase(product, false);
    },
    likeProductBase(product, isLike) {
      if (!this.isAuthorized) {
        this.$store.dispatch('setNotification', { content: this.content[this.lang].needAuthorize });
        return;
      }
      productController.likeProductHttpPost(product.id, isLike)
        .then((data) => {
          for (let i = 0; i < this.userProducts.length; i++) {
            let product = this.userProducts[i];
            if (product.id === data.id) {
              product.likes = data.likes;
              product.dislikes = data.dislikes;
              product.userContextIsLike = data.userContextIsLike;
              break;
            }
          }
        });
    },
  },
}
</script>

<style scoped>
</style>
