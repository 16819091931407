<template>
  <div>
    <div class="text-center bolder">
      <span v-if="product">{{ content[lang].titleUpdate }}</span>
      <span v-else>{{ content[lang].title }}</span>
    </div>
    <div class="col-md-6 offset-md-3 pt-3">
      <ProductCreate
        ref="productCreate"
        :product="product"
        :productTypes="productTypes"
        @submit="submit"
        @uploadImage="uploadImage"
        @deleteCurrentFile="deleteCurrentFile">
      </ProductCreate>
    </div>
  </div>
</template>

<script>

import ProductCreate from '../../components/products/ProductCreate';
import { productController } from '../../services/apiService';

export default {
  data () {
    return {
      product: null,
      productTypes: [],

      content: {
        ru: {
          title: 'Создание товара',
          titleUpdate: 'Обновление товара',
          productCreated: 'Товар создан',
          productUpdated: 'Товар обновлен',
        },
        en: {
          title: 'Create product',
          titleUpdate: 'Update product',
          productCreated: 'Product was created',
          productUpdated: 'Product was updated',
        },
      },
    }
  },
  components: {
    ProductCreate,
  },
  async created() {
    await this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  methods: {
    async init() {
      const productId = this.$route.query.productId;
      if (productId) {
        productController.getProductHttpGet(productId)
          .then((data) => {
            this.product = data;
          });
      }
      this.productTypes = await productController.getProductTypesCacheHttpGet();
    },
    submit(product) {
      if (this.product) {
        this.updateProduct(product, this.product.id);
      } else {
        this.createProduct(product);
      }
    },
    createProduct(product) {
      productController.createProductHttpPost(product)
        .then(() => {
          this.$refs.productCreate.clear();
          this.productAction();
        });
    },
    updateProduct(product, productId) {
      productController.updateProductHttpPatch(product, productId)
        .then(() => {
          this.productAction();
        });
    },
    uploadImage(formData) {
      productController.addProductImageHttpPost(formData, this.product.id)
        .then((data) => {
          this.product.files = data.files;
          this.productAction();
        });
    },
    deleteCurrentFile(fileId) {
      productController.deleteProductImageHttpDelete(fileId)
        .then(() => {
          this.productAction();
        });
    },
    productAction() {
      let notification = { content: null };
      if (this.product) {
        notification.content = this.content[this.lang].productUpdated;
      } else {
        notification.content = this.content[this.lang].productCreated;
      }
      this.$store.dispatch('setNotification', notification);
    },
  },
}
</script>

<style scoped>
</style>
