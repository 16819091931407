<template>
  <div>
    <div v-if="!isRejectSelected" class="col-md-12">
      <div class="row">
        <div class="col-md-6 mb-3">
          <button
            @click="approveProduct(true)"
            type="button"
            class="btn button-default btn-success">{{ content[lang].approve }}
          </button>
        </div>
        <div class="col-md-6 mb-3">
          <button
            @click="isRejectSelected = true"
            type="button"
            class="btn button-default btn-danger">{{ content[lang].reject }}
          </button>
        </div>
      </div>
    </div>
    <div v-else class="col-md-12">
      <div>
        <InputBox
          :modelValue="adminComment"
          @update:modelValue="adminComment = $event"
          :title="content[lang].adminComment"
          type="text"
          :isError="adminCommentError"
          :messageError="content[lang].required">
        </InputBox>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <button
            @click="isRejectSelected = false"
            type="button"
            class="btn button-default btn-secondary mb-1">{{ content[lang].cancel }}
          </button>
        </div>
        <div class="col-md-6 mb-3">
          <button
            @click="approveProduct(false)"
            type="button"
            :disabled="adminCommentError"
            class="btn button-default btn-danger mb-1">{{ content[lang].reject }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputBox from '../InputBox';

export default {
  data () {
    return {
      isRejectSelected: false,
      adminComment: '',
      status: {
        rejected: 'Rejected',
        approved: 'Approved',
      },

      content: {
        ru: {
          approve: 'Подтвердить',
          reject: 'Отклонить',
          cancel: 'Отменить',
          adminComment: 'Комментарий администратора',
          required: 'Требуется заполнить',
        },
        en: {
          approve: 'Approve',
          reject: 'Reject',
          cancel: 'Cancel',
          adminComment: 'Admin comment',
          required: 'Field is required',
        },
      },
    }
  },
  components: {
    InputBox,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    adminCommentError() {
      if (this.adminComment.length) {
        return false;
      }
      return true;
    },
  },
  methods: {
    approveProduct(isApprove) {
      const productApprove = {
        status: isApprove ? this.status.approved : this.status.rejected,
        adminComment: this.adminComment,
      };
      this.$emit('approveProduct', productApprove);
      if (this.isRejectSelected) {
        this.isRejectSelected = false;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
