import api from './httpService';
import { cacheService } from './cacheService';
import { CacheKeys } from '../consts/cacheKeys';

export const advertisementController = {
  controllerName: 'advertisement/',
  getAdvertisementListPublicCacheHttpGet: async function() {
    let advertisements = cacheService.getItem(CacheKeys.advertisements);
    if (advertisements) return advertisements;
    advertisements = await api({ url: `${this.controllerName}get-advertisement-list-public`, apiUrl: process.env.VUE_APP_API_URL_ADVERT }).get();
    if (advertisements) {
      cacheService.setItem(CacheKeys.advertisements, advertisements, 60);
    }
    return advertisements;
  },
};
