<template>
  <div>
    <div class="content-block">
      <div class="content-text">
        <span>{{ content[lang].imageLoad }}</span>
      </div>
      <div class="content-info">
        <InfoIcon
          icon="question-circle.svg"
          :content="content[lang].formatsSupported">
        </InfoIcon>
      </div>
    </div>
    <div v-if="selectedFile" class="files-block pt-3">
      <div
        class="file-card background-color-brand">
        <span>{{ selectedFile.name }}</span>
        <img
          @click="closeFile"
          class="close-icon"
          src="@/assets/icons/close-icon.svg">
      </div>
    </div>
    <div v-if="currentFile" class="files-block pt-3">
      <div
        class="file-card background-color-brand">
        <span>{{ content[lang].currentFile }}: </span>
        <span>{{ currentFile.name }}</span>
        <img
          @click="deleteCurrentFile"
          class="close-icon"
          src="@/assets/icons/close-icon.svg">
      </div>
    </div>
    <div v-if="imagePreview" class="mt-3">
      <img class="image-preview" :src="imagePreview">
    </div>
    <div v-show="false">
      <input
        ref="input"
        type="file"
        @change="imageChanged">
    </div>
    <div v-if="!currentFile">
      <div v-if="isImageError" class="pt-3">
        <button
          @click="addImage"
          type="button"
          class="btn button-default btn-info">
          <span v-if="!addImageText">{{ content[lang].addImage }}</span>
          <span v-else>{{ addImageText }}</span>
        </button>
        <div v-if="isFormatError">
          <small class="text-danger">{{ content[lang].errorFormat }}</small>
        </div>
        <div v-if="isSizeError">
          <small class="text-danger">{{ content[lang].errorSize(maxSize) }}</small>
        </div>
      </div>
      <div v-else-if="!uploadHidden" class="pt-3">
        <button
          @click="uploadImage"
          type="button"
          class="btn button-default btn-info">{{ content[lang].uploadImage }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { checkFile } from '../services/validateService';
import { getFullPathWithScreenSize } from '../services/workService';
import InfoIcon from './InfoIcon';

export default {
  data () {
    return {
      selectedFile: null,
      formatsSupported: ['.png', '.jpg', '.jpeg'],
      currentFile: null,
      maxSize: 10,
      imagePreview: null,

      content: {
        ru: {
          imageLoad: 'Загрузка изображения',
          formatsSupported: 'Форматы: png, jpg, jpeg',
          addImage: 'Добавить',
          uploadImage: 'Загрузить',
          errorFormat: 'Ошибка формата',
          currentFile: 'Текущий файл',
          errorSize: (maxSize) => `Слишком большой файл. Допустимый размер: до ${maxSize} mb`,
        },
        en: {
          imageLoad: 'Image loading',
          formatsSupported: 'Formats: png, jpg, jpeg',
          addImage: 'Add',
          uploadImage: 'Upload',
          errorFormat: 'Format error',
          currentFile: 'Current file',
          errorSize: (maxSize) => `File is too large. Allowed size: up to ${maxSize} mb`,
        },
      },
    }
  },
  components: {
    InfoIcon,
  },
  props: {
    files: {
      type: Array,
      default: null,
    },
    addImageText: {
      type: String,
      default: null,
    },
    uploadReset: {
      type: Boolean,
      default: false,
    },
    uploadHidden: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isFormatError() {
      if (this.selectedFile) {
        return !checkFile(this.selectedFile.name, this.formatsSupported);
      }
      return false;
    },
    isSizeError() {
      if (this.selectedFile) {
        return this.selectedFile.size > this.maxSize * 1024 * 1024;
      }
      return false;
    },
    isImageError() {
      if (!this.selectedFile || this.isFormatError || this.isSizeError) {
        return true;
      }
      return false;
    },
    isUploadedImageHasError() {
      if (this.isFormatError || this.isSizeError) {
        return true;
      }
      return false;
    },
  },
  watch: {
    files() {
      this.init();
    },
    isUploadedImageHasError() {
      this.$emit('setImageError', this.isUploadedImageHasError);
    },
  },
  methods: {
    init() {
      if (this.files && this.files.length) {
        this.currentFile = this.files[0];
        this.imagePreview = getFullPathWithScreenSize(this.currentFile.path);
        this.selectedFile = null;
      }
    },
    imageChanged() {
      this.selectedFile = this.$refs.input.files[0];
      this.imagePreview = URL.createObjectURL(this.selectedFile);
      this.$refs.input.value = null;
    },
    addImage() {
      this.$refs.input.click();
    },
    closeFile() {
      this.selectedFile = null;
      this.imagePreview = null;
    },
    uploadImage() {
      const formData = this.getFormData();
      this.$emit('uploadImage', formData);
      if (this.uploadReset) {
        this.reset();
      }
    },
    deleteCurrentFile() {
      this.$emit('deleteCurrentFile', this.currentFile.id);
      this.currentFile = null;
      this.imagePreview = null;
    },
    getImageFormData() {
      if (!this.selectedFile) {
        return null;
      }
      return this.getFormData();
    },
    getFormData() {
      const formData = new FormData();
      formData.append('formFile', this.selectedFile, this.selectedFile.name);
      return formData;
    },
    reset() {
      this.selectedFile = null;
      this.currentFile = null;
      this.imagePreview = null;
    },
  },
}
</script>

<style lang="scss" scoped>
.content-block {
  .content-text {
    display: inline-block;
  }
  .content-info {
    display: inline-block;
    margin-left: 6px;
  }
}
.files-block {
  display: inline-block;
}
.file-card {
  position: relative;
  color: #FFFFFF;
  padding: 5px 40px 5px 20px;
  border-radius: 6px;
  .close-icon {
    position: absolute;
    width: 20px;
    top: 7px;
    right: 12px;
    cursor: pointer;
  }
}
.image-preview {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 6px;
}
</style>
