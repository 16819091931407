<template>
  <div class="product-comment-block">
    <div>
      <ProfileImage
        :userId="comment.userId"
        :userName="comment.userName"
        :image="comment.userImage"
        :isMinSize="true">
      </ProfileImage>
    </div>
    <div class="product-comment-body mt-2">
      <div>
        <div v-if="comment.productCommentParentId" class="user-name-reply">@{{ comment.userName }}</div>
        <div class="text-pre-wrap">{{ comment.content }}</div>
      </div>
    </div>
    <div class="product-created-date mt-2 small-text">
      <span>{{ dateUTCToLocalFunc(comment.created) }}</span>
    </div>
    <div class="product-comment-footer mt-2">
      <div>
        <div class="mt-2 p-0">
          <div class="likes">
            <span>
              <span @click="like" class="icon">
                <img v-if="comment.userContextIsLike === true" src="@/assets/icons/thumbs-up-filled.svg">
                <img v-else src="@/assets/icons/thumbs-up.svg">
              </span>
              <span>{{ comment.likes }}</span>
            </span>
            <span class="dislike">
              <span @click="dislike" class="icon">
                <img v-if="comment.userContextIsLike === false" src="@/assets/icons/thumbs-down-filled.svg">
                <img v-else src="@/assets/icons/thumbs-down.svg">
              </span>
              <span>{{ comment.dislikes }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <ProductCommentInput
          @create="commentReplyCreate">
        </ProductCommentInput>
      </div>
    </div>
  </div>
</template>

<script>

import ProfileImage from '../ProfileImage';
import ProductCommentInput from './ProductCommentInput';
import { dateUTCToLocal } from '../../services/workService';

export default {
  data () {
    return {
    }
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProfileImage,
    ProductCommentInput,
  },
  methods: {
    like() {
      this.$emit('productCommentLike', { commentId: this.comment.id, isLike: true });
    },
    dislike() {
      this.$emit('productCommentLike', { commentId: this.comment.id, isLike: false });
    },
    commentReplyCreate(value) {
      this.$emit('commentReplyCreate', { commentId: this.comment.id, content: value });
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
  },
}
</script>

<style lang="scss" scoped>
.product-comment-block {
  padding: 10px;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem;
  .product-comment-body {
    position: relative;
  }
  .product-created-date {
    position: relative;
  }
  .product-comment-footer {
    border-top: 1px solid rgba(0,0,0,.125);
    .likes {
      .dislike {
        margin-left: 10px;
      }
      .icon {
        cursor: pointer;
        img {
          width: 16px;
        }
      }
    }
    .reply-button {
      @media screen and (max-width: 767px) {
        text-align: left !important;
      }
    }
  }
}
</style>
