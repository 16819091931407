export const checkEmail = email => {
  /* eslint-disable no-useless-escape */
  let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export const checkPassword = password => {
  return password && password.length > 5;
}

export const checkFile = (fileName, formats) => {
  const format = fileName.substring(fileName.indexOf('.'));
  return formats.includes(format);
}

export const checkPointInRectangle = (point, rectangle) => {
  return point.x >= rectangle.x && point.x <= (rectangle.width + rectangle.x) 
    && point.y >= rectangle.y && point.y <= (rectangle.height + rectangle.y);
}

export const checkMobileScreen = () => {
  return window.innerWidth < 768;
}

export const checkMobileBrowser = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
