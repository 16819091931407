import api from './httpService';
import { cacheService } from './cacheService';
import { CacheKeys } from '../consts/cacheKeys';
import { ContentTypes } from '../consts/contentTypes';

export const userController = {
  controllerName: 'user/',
  getCountriesCacheHttpGet: async function() {
    let countries = cacheService.getItem(CacheKeys.countries);
    if (countries) return countries;
    countries = await api({ url: `${this.controllerName}get-countries` }).get();
    if (countries) {
      cacheService.setItem(CacheKeys.countries, countries, null);
    }
    return countries;
  },
  createUserHttpPost: function(email, name, password) {
    return api({ url: `${this.controllerName}create` })
      .post({
        email,
        name,
        password,
      });
  },
  sendNewRegistrationCodeHttpPost: function(email) {
    return api({ url: `${this.controllerName}send-new-registration-code` })
      .post({
        email,
      });
  },
  userActivationHttpPost: function(email, code) {
    return api({ url: `${this.controllerName}user-activation` })
      .post({
        email,
        code,
      });
  },
  loginUserHttpPost: function(email, password) {
    return api({ url: `${this.controllerName}login` })
      .post({
        email,
        password,
      });
  },
  passwordChangeRequestHttpPost: function(email) {
    return api({ url: `${this.controllerName}password-change-request` })
      .post({
        email,
      });
  },
  passwordChangeApproveHttpPost: function(email, password, code) {
    return api({ url: `${this.controllerName}password-change-approve` })
      .post({
        email,
        password,
        code,
      });
  },
  getUserPublicHttpGet: function(userId) {
    return api({ url: `${this.controllerName}get-user-public-${userId}` })
      .get();
  },
  updateUserHttpPatch: function(age, gender, countryId, isMarried, description) {
    return api({ url: `${this.controllerName}update` })
      .patch({
        age,
        gender,
        countryId,
        isMarried,
        description,
      });
  },
  getUserHttpGet: function() {
    return api({ url: `${this.controllerName}get-user` })
      .get();
  },
  subscribeUserHttpPost: function(userId) {
    return api({ url: `${this.controllerName}subscribe-user-${userId}` })
      .post();
  },
  unsubscribeUserHttpPost: function(userId) {
    return api({ url: `${this.controllerName}unsubscribe-user-${userId}` })
      .post();
  },
  getSubscriptionsHttpGet: function() {
    return api({ url: `${this.controllerName}get-subscriptions` })
      .get();
  },
  addUserImageHttpPost: function(formFile) {
    return api({ url: `${this.controllerName}add-user-image` })
      .post(formFile);
  },
  deleteUserImageHttpDelete: function() {
    return api({ url: `${this.controllerName}delete-user-image` })
      .delete();
  },
  refreshTokenHttpPost: function() {
    return api({ url: `${this.controllerName}token-refresh`, isTokenRefresh: true })
      .post();
  },
  deleteUserAccountHttpDelete: function() {
    return api({ url: `${this.controllerName}delete-user-account` })
      .delete();
  },
  getUsersByAdminHttpPost: function(userQuery) {
    return api({ url: `${this.controllerName}get-users-by-admin` })
      .post({
        userId: userQuery.userId,
        email: userQuery.email,
        name: userQuery.name,
        isBlocked: userQuery.isBlocked,
        skip: userQuery.skip,
        take: userQuery.take,
      });
  },
  blockUserByAdminHttpPost: function(userId, isBlocked) {
    return api({ url: `${this.controllerName}block-user-by-admin` })
      .post({
        userId,
        isBlocked
      });
  },
};

export const productController = {
  controllerName: 'product/',
  createProductHttpPost: function(model) {
    let request = model.formFile || new FormData();
    request.append('name', model.name);
    request.append('productTypeId', model.productTypeId);
    request.append('description', model.description);
    request.append('mark', model.mark);
    return api({ url: `${this.controllerName}create`, contentType: ContentTypes.formData })
      .post(request);
  },
  getProductHttpGet: function(productId) {
    return api({ url: `${this.controllerName}get-product-${productId}` })
      .get();
  },
  updateProductHttpPatch: function(model, productId) {
    let request = model.formFile || new FormData();
    request.append('name', model.name);
    request.append('productTypeId', model.productTypeId);
    request.append('description', model.description);
    request.append('mark', model.mark);
    return api({ url: `${this.controllerName}update-product-${productId}`, contentType: ContentTypes.formData })
      .patch(request);
  },
  deleteProductHttpDelete: function(productId) {
    return api({ url: `${this.controllerName}delete-product-${productId}` })
      .delete();
  },
  pendingProductByUserHttpPatch: function(productId) {
    return api({ url: `${this.controllerName}pending-product-by-user/${productId}` })
      .patch();
  },
  getProductsHttpPost: function(productQuery) {
    return api({ url: `${this.controllerName}get-products` })
      .post({
        userId: productQuery.userId,
        name: productQuery.name,
        dateFrom: productQuery.dateFrom,
        dateTo: productQuery.dateTo,
        skip: productQuery.skip,
        take: productQuery.take,
        isAscending: productQuery.isAscending,
      });
  },
  addProductImageHttpPost: function(formFile, productId) {
    return api({ url: `${this.controllerName}add-product-image-${productId}` })
      .post(formFile);
  },
  deleteProductImageHttpDelete: function(imageId) {
    return api({ url: `${this.controllerName}delete-product/image-${imageId}` })
      .delete();
  },
  likeProductHttpPost: function(productId, isLike) {
    return api({ url: `${this.controllerName}like-product-${productId}-${isLike}` })
      .post();
  },
  сreateProductCommentHttpPost: function(productComment) {
    return api({ url: `${this.controllerName}create-product-comment` })
      .post({
        content: productComment.content,
        productId: productComment.productId,
        productCommentParentId: productComment.productCommentParentId,
      });
  },
  likeProductCommentHttpPost: function(productCommentId, isLike) {
    return api({ url: `${this.controllerName}like-product-comment-${productCommentId}/${isLike}` })
      .post();
  },
  getProductTypesCacheHttpGet: async function() {
    let productTypes = cacheService.getItem(CacheKeys.productTypes);
    if (productTypes) return productTypes;
    productTypes = await api({ url: `${this.controllerName}get-product-types` }).get();
    if (productTypes) {
      cacheService.setItem(CacheKeys.productTypes, productTypes, 1440);
    }
    return productTypes;
  },
  getProductsPublicHttpPost: function(productQuery) {
    return api({ url: `${this.controllerName}get-products-public` })
      .post({
        userId: productQuery.userId,
        name: productQuery.name,
        dateFrom: productQuery.dateFrom,
        dateTo: productQuery.dateTo,
        skip: productQuery.skip,
        take: productQuery.take,
        isAscending: productQuery.isAscending,
        userName: productQuery.userName,
        productTypeId: productQuery.productTypeId,
      });
  },
  getProductPublicHttpGet: function(productId) {
    return api({ url: `${this.controllerName}get-product-public/${productId}` })
      .get();
  },
  getProductCommentsPublicHttpPost: function(productCommentsQuery) {
    return api({ url: `${this.controllerName}get-product-comments` })
      .post({
        productId: productCommentsQuery.productId,
        skip: productCommentsQuery.skip,
        take: productCommentsQuery.take,
        isAscending: productCommentsQuery.isAscending,
      });
  },
  getProductsByAdminHttpPost: function(productQuery) {
    return api({ url: `${this.controllerName}get-products-by-admin` })
      .post({
        userId: productQuery.userId,
        name: productQuery.name,
        dateFrom: productQuery.dateFrom,
        dateTo: productQuery.dateTo,
        skip: productQuery.skip,
        take: productQuery.take,
        isAscending: productQuery.isAscending,
        status: productQuery.status,
        userName: productQuery.userName,
      });
  },
  approveProductByAdminHttpPost: function(productApproveInput, productId) {
    return api({ url: `${this.controllerName}approve-product-by-admin-${productId}` })
      .post({
        status: productApproveInput.status,
        adminComment: productApproveInput.adminComment,
      });
  },
};
