<template>
  <div
    :class="{
      'search-block-active': active,
      'search-block-has-value': isSearchHasValue,
    }"
    class="search-block box-shadow-default">
    <div class="search-icon-block">
      <span @click="searchBoxClick">
        <img class="search-icon" src="@/assets/icons/search.svg">
      </span>
      <span v-show="isSearchHasValue" @click="searchBoxClear" class="ml-3">
        <img class="search-icon" src="@/assets/icons/close-icon.svg">
      </span>
    </div>
    <div v-show="active" class="search-content-block">
      <InputBox
        @input="searchEvent"
        v-model="productQuery.name"
        :content="productQuery.name"
        :title="content[lang].productName"
        type="text">
      </InputBox>
      <SelectBox
        class="mb-3"
        :modelValue="productQuery.productTypeId"
        @update:modelValue="productQuery.productTypeId = $event"
        :model="productQuery.productTypeId"
        :label="content[lang].productType"
        :defaultOption="false"
        :options="productTypesOptions">
      </SelectBox>
      <InputBox
        @input="searchEvent"
        v-model="productQuery.userName"
        :content="productQuery.userName"
        :title="content[lang].userName"
        type="text">
      </InputBox>
    </div>
  </div>
</template>

<script>

import InputBox from '../InputBox';
import SelectBox from '../SelectBox';

export default {
  data () {
    return {
      active: false,
      timer: null,
      timerValue: 1000,
      productQuery: {
        name: null,
        userName: null,
        productTypeId: null,
      },

      content: {
        ru: {
          productName: 'Название продукта',
          userName: 'Имя пользователя',
          productType: 'Тип продукта'
        },
        en: {
          productName: 'Product name',
          userName: 'User name',
          productType: 'Product type'
        },
      },
    }
  },
  props: {
    productQueryProp: {
      type: Object,
      default: null,
    },
    productTypes: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    InputBox,
    SelectBox,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isSearchHasValue() {
      if ((this.productQuery.name && this.productQuery.name.length) ||
        (this.productQuery.userName && this.productQuery.userName.length) ||
        this.productQuery.productTypeId) {
        return true;
      }
      return false;
    },
    productTypesOptions() {
      let productTypesOptions = [];
      if (this.productTypes.length) {
        const isName = this.lang === 'en';
        this.productTypes.forEach((type) => {
          productTypesOptions.push({
            text: isName ? type.name : type.description,
            value: type.id,
          });
        });
      }
      return productTypesOptions;
    },
  },
  watch: {
    'productQuery.productTypeId'() {
      if (this.productQuery.productTypeId) {
        setTimeout(this.sendSearchEvent, 1);
      }
    },
  },
  methods: {
    init() {
      this.checkQuery();
    },
    checkQuery() {
      const userName = this.$route.query.userName;
      if (userName && !this.productQuery.userName) {
        this.productQuery.userName = userName;
        this.sendSearchEvent();
      }
    },
    searchBoxClick() {
      this.active = !this.active;
    },
    searchBoxClear() {
      this.productQuery.name = null;
      this.productQuery.userName = null;
      this.productQuery.productTypeId = null;
      setTimeout(this.sendSearchEvent, 1);
    },
    searchEvent() {
      if (!this.timer) {
        this.setTimer();
      } else {
        clearTimeout(this.timer);
        this.setTimer();
      }
    },
    setTimer() {
      this.timer = setTimeout(this.sendSearchEvent, this.timerValue);
    },
    sendSearchEvent() {
      this.$emit('searchEvent', this.productQuery);
    },
  },
}
</script>

<style lang="scss" scoped>
.search-block {
  position: relative;
  display: inline-block;
  padding: 6px 10px 6px 10px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  transition: all 1s;
  .search-icon-block {
    display: inline-block;
    .search-icon {
      width: 20px;
      top: 7px;
      right: 12px;
      cursor: pointer;
    }
  }
  .search-content-block {
    display: block;
    margin-top: 10px;
    .search-field-block {
      text-align: center;
    }
  }
}
.search-block-active {
  display: block;
}
.search-block-has-value {
  border: 1px solid #296b14b0;
  background-color: #51a9381a;
}
</style>
