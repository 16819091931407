import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouteTypes } from './consts/routeTypes';

// checking authorization
store.dispatch('readToken');
router.beforeEach((to, from, next) => {
  const isAuthorized = store.getters.isAuthorized;
  const isAdmin = store.getters.isAdmin;
  const routeType = to.meta.type;

  if (routeType === RouteTypes.default) {
    next();
  } else if (routeType === RouteTypes.unauthorized) {
    if (!isAuthorized) {
      next();
    } else {
      next('/profile');
    }
  } else if (routeType === RouteTypes.authorized) {
    if (isAuthorized) {
      next();
    } else {
      next('/login');
    }
  } else if (routeType === RouteTypes.admin) {
    if (isAuthorized && isAdmin) {
      next();
    } else {
      next('/');
    }
  }
});

// if (process.env.VUE_APP_TYPE_ENV === 'cordova_production') {
//   let script = document.createElement('script');
//   script.type = 'text/javascript';
//   script.src = 'cordova.js';
//   document.getElementsByTagName('head')[0].appendChild(script);
// }

createApp(App)
.use(router)
.use(store)
.mount('#app');