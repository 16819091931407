<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3 pt-3">
      <Product
        v-if="product"
        :product="product"
        :productViewType="productViewTypes.public"
        @likeProduct="likeProduct"
        @dislikeProduct="dislikeProduct"
        @profileView="profileView">
      </Product>
    </div>
  </div>
</template>

<script>

import Product from '../../components/products/Product';
import { productController } from '../../services/apiService';
import { ProductViewType } from '../../consts/productViewType';

export default {
  data () {
    return {
      productId: null,
      product: null,
      productViewTypes: ProductViewType,

      content: {
        ru: {
          title: 'Товар',
          needAuthorize: 'Нужно войти в аккаунт',
        },
        en: {
          title: 'Product',
          needAuthorize: 'Need to login in account',
        },
      },
    }
  },
  components: {
    Product,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
  },
  methods: {
    init() {
      this.productId = this.$route.query.productId;
      if (!this.productId) {
        return;
      }
      productController.getProductPublicHttpGet(this.productId)
        .then((data) => {
          this.product = data;
        });
    },
    likeProduct(product) {
      this.likeProductBase(product, true);
    },
    dislikeProduct(product) {
      this.likeProductBase(product, false);
    },
    likeProductBase(product, isLike) {
      if (!this.isAuthorized) {
        this.$store.dispatch('setNotification', { content: this.content[this.lang].needAuthorize });
        return;
      }
      productController.likeProductHttpPost(product.id, isLike)
        .then((data) => {
          this.product.likes = data.likes;
          this.product.dislikes = data.dislikes;
          this.product.userContextIsLike = data.userContextIsLike;
        });
    },
    profileView(userId) {
      this.$router.push({ path: '/profile-view', query: { userId: userId } });
    },
  },
}
</script>

<style scoped>
.close-icon {
  width: 20px;
  top: 7px;
  right: 12px;
  cursor: pointer;
}
</style>
