import axios from 'axios';
import Store from '../store';
import router from '../router';
import { NotificationTypes } from '../consts/notificationTypes';
import { ContentTypes } from '../consts/contentTypes';
import { userController } from './apiService';

function startLoading() {
  Store.dispatch('setLoading', true);
}
function stopLoading() {
  Store.dispatch('setLoading', false);
}
function getAuthorization(token) {
  return `Bearer ${token}`;
}
function responseError(e) {
  let notification = {
    type: null,
    content: null,
    value: null,
  };
  if (e.response) {
    const response = e.response;
    if (response.status === 401) {
      const tokenAccess = Store.getters.getTokenAccess;
      if (tokenAccess) {
        Store.dispatch('removeTokenAccess');
        return userController.refreshTokenHttpPost()
          .then(async (data) => {
            const tokenData = {
              tokenAccess: data.tokenAccess,
              tokenRefresh: data.tokenRefresh,
            };
            Store.dispatch('login', tokenData);
            e.config.headers.Authorization = getAuthorization(data.tokenAccess);
            const response = await axios.request(e.config);
            return response.data;
          });
      } else {
        Store.dispatch('logout');
        router.push('/');
      }
    } else if (response.data.errors) {
      notification.type = NotificationTypes.error;
      notification.content = response.data.errors[0].description;
      if (response.data.errors[0].value) {
        notification.value = response.data.errors[0].value;
      }
    }
  } else {
    notification.type = NotificationTypes.serverError;
    notification.content = 'Server Error';
  }
  if (notification.type) {
    Store.dispatch('setNotification', notification);
  }
  stopLoading();
  return Promise.reject(e);
}

export default function api(apiConfig) {
  let url = apiConfig.url;
  let apiUrl = apiConfig.apiUrl;
  let isTokenRefresh = apiConfig.isTokenRefresh;
  let contentType = apiConfig.contentType;
  if (!apiUrl) {
    apiUrl = process.env.VUE_APP_API_URL;
  }
  url = `${apiUrl}${url}`;
  let headers = { 'Accept': 'application/json;charset=UTF-8'};
  if (!contentType) {
    contentType = ContentTypes.json;
  }
  headers['Content-Type'] = contentType;
  let token = null;
  if (isTokenRefresh) {
    token = Store.getters.getTokenRefresh;
  } else {
    token = Store.getters.getTokenAccess;
  }
  if (token) {
    headers.Authorization = getAuthorization(token);
  }
  const config = {
    headers,
  };
  startLoading();
  return {
    post: data => axios.post(url, data, config)
      .then(response => {
        stopLoading();
        return response.data;
      })
      .catch(e => {
        return responseError(e);
      }),
      
    get: () => axios.get(url, config)
      .then(response => {
        stopLoading();
        return response.data;
      })
      .catch(e => {
        return responseError(e);
      }),

    put: data => axios.put(url, data, config)
      .then(response => {
        stopLoading();
        return response.data;
      })
      .catch(e => {
        return responseError(e);
      }),

    patch: data => axios.patch(url, data, config)
      .then(response => {
        stopLoading();
        return response.data;
      })
      .catch(e => {
        return responseError(e);
      }),

    delete: () => axios.delete(url, config)
      .then(response => {
        stopLoading();
        return response.data;
      })
      .catch(e => {
        return responseError(e);
      }),
  };
}
