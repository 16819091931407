<template>
  <div @click="showProfile" class="subscription-block block-default block-hover">
    <div class="subscription-header">
      <ProfileImage
        :userName="item.name"
        :image="item.userImage">
      </ProfileImage>
    </div>
    <div class="mt-3">
      <span>{{ content[lang].products }}: </span>
      <span>{{ item.productsCount }}</span>
    </div>
    <div>
      <span>{{ content[lang].productLastUpdate }}: </span>
      <span v-if="item.productLastUpdate">{{ dateUTCToLocalFunc(item.productLastUpdate) }}</span>
      <span v-else>-</span>
    </div>
  </div>
</template>

<script>

import { dateUTCToLocal } from '../services/workService';
import ProfileImage from '../components/ProfileImage';

export default {
  data () {
    return {
      content: {
        ru: {
          products: 'Товары',
          productLastUpdate: 'Последнее обновление',
        },
        en: {
          products: 'Products',
          productLastUpdate: 'Last update',
        },
      },
    }
  },
  components: {
    ProfileImage,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  methods: {
    showProfile() {
      this.$router.push({ path: '/profile-view', query: { userId: this.item.id } });
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
  }
}
</script>

<style lang="scss" scoped>
.subscription-block {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  .subscription-header {
    pointer-events: none;
  }
}
</style>
