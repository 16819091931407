<template>
  <div class="home-card box-shadow-default">
    <div v-if="icon" class="icon-block">
      <img :src="getIconPath(icon)">
    </div>
    <div class="content-block">
      <div class="header font-size-20 mt-4">{{ header }}</div>
      <div class="font-size-18 mt-3">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    header: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    getIconPath (iconName) {
      return require(`@/assets/icons/home/${iconName}`);
    }
  },
}
</script>

<style scoped lang="scss">
.home-card {
  position: relative;
  min-height: 300px;
  padding: 20px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  .icon-block {
    text-align: center;
    img {
      max-height: 80px;
    }
  }
  .content-block {
    .header {
      text-align: center;
      font-weight: 600;
    }
  }
}
</style>
