import { ScreenSizes, SizeTypeTemplate } from '../consts/screenSizes';

export const dateUTCToLocal = date => {
  if (!date) {
    return '-';
  }
  function checkDateNumber(number) {
    if (number < 10) {
      const value = `0${number.toString()}`;
      return value;
    }
    return number;
  }
  if (date.includes('Z')) {
    date = date.replace('Z', '');
  }
  const dateValue = new Date(date);
  var minutesOffsetAdd = (-1) * dateValue.getTimezoneOffset();
  const d = new Date(dateValue.getTime() + minutesOffsetAdd * 60000);
  const dformat =
    [
      checkDateNumber(d.getDate()),
      checkDateNumber(d.getMonth() + 1),
      d.getFullYear()
    ].join('.') + ' ' +
    [
      checkDateNumber(d.getHours()),
      checkDateNumber(d.getMinutes()),
      // checkDateNumber(d.getSeconds())
    ].join(':');

  return dformat;
}

export const getApiUrl = () => {
  return process.env.VUE_APP_API_URL;
}

export const getWebsiteUrl = () => {
  return process.env.VUE_APP_API_URL.replace('/api/', '');
}

export const getFullPathWithScreenSize = (path) => {
  return `${getApiUrl()}${getPathWithScreenSize(path)}`;
}

export const getUserImageDefault = () => {
  return require(`@/assets/icons/user-image-default.svg`);
}

// private methods

const getScreenSizeType = () => {
  const width = window.innerWidth;
  if (width < 768) {
    return ScreenSizes.sm;
  } else if (width < 1200) {
    return ScreenSizes.md;
  }
  return ScreenSizes.lg;
}

const getPathWithScreenSize = (path) => {
  const screenSize = getScreenSizeType();
  return path.replace(SizeTypeTemplate, screenSize);
}
