<template>
  <div>
    <div v-if="!hideTitle" class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div v-if="!activationSuccess">
      <InputBox
        :modelValue="email"
        @update:modelValue="email = $event"
        :content="email"
        title="Email"
        type="text"
        :isError="emailError"
        :messageError="content[lang].emailError">
      </InputBox>
      <InputBox
        :modelValue="code"
        @update:modelValue="code = $event"
        :content="code"
        :title="content[lang].enterCode"
        type="text"
        :isError="codeError"
        :messageError="content[lang].codeError">
      </InputBox>
    </div>
    <div v-if="!activationSuccess" class="pt-3 text-center">
      <button
        @click="activate"
        :disabled="activateDisabled" type="button"
        class="btn button-default btn-success">{{ content[lang].activate }}
      </button>
    </div>
    <div v-else class="pt-3 text-center">
      <div class="text-success">{{ content[lang].activationSuccess }}</div>
      <button
        @click="login" type="button" class="btn button-default btn-success">{{ content[lang].login }}
      </button>
    </div>
  </div>
</template>

<script>

import { checkEmail } from '../services/validateService';
import { userController } from '../services/apiService';
import InputBox from './InputBox';

export default {
  data () {
    return {
      email: this.emailProp,
      code: '',
      activationSuccess: false,
      activationError: false,

      content: {
        ru: {
          title: 'Активировать аккаунт',
          emailError: 'Неверный email',
          enterCode: 'Введите код',
          codeError: 'Требуется ввести код',
          activate: 'Активировать',
          activationSuccess: 'Аккаунт активирован',
          login: 'Войти',
        },
        en: {
          title: 'Account activation',
          emailError: 'Incorrect email',
          enterCode: 'Enter code',
          codeError: 'Code is required',
          activate: 'Activate',
          activationSuccess: 'Account activated',
          login: 'Login',
        },
      },
    }
  },
  props: {
    emailProp: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputBox,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
    codeError() {
      if (this.code.length === 0) {
        return true;
      }
      return false;
    },
    activateDisabled() {
      if (this.emailError || this.codeError) {
        return true;
      }
      return false;
    },
  },
  watch: {
    emailProp() {
      this.email = this.emailProp;
    },
  },
  methods: {
    activate() {
      this.activationSuccess = false;
      userController.userActivationHttpPost(this.email, this.code)
        .then(() => {
          this.activationSuccess = true;
          this.$emit('activationSuccess', true);
        });
    },
    login() {
      this.$router.push({ path: '/login', query: { email: this.email } });
    },
  },
}
</script>

<style scoped>
</style>
