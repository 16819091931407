<template>
  <div class="comment-box-block">
    <div class="comment-count">
      <span>{{ content[lang].commentsCount }}: {{ commentsCount }}</span>
    </div>
    <div v-if="isActive">
      <div class="mt-2">
        <ProductCommentInput
          @create="commentCreate">
        </ProductCommentInput>
      </div>
      <div v-if="commentsCount" class="comment-box mt-2">
        <div>
          <span @click="showComments()" class="pointer noselect bolder">
            <span v-show="!isCommentsVisible">{{ content[lang].showComments }}</span>
            <span v-show="isCommentsVisible">{{ content[lang].hideComments }}</span>
          </span>
        </div>
        <div
          v-if="isCommentsVisible"
          :class="{
            'comment-list-single': productComments.length === 1,
            'comment-list': productComments.length > 1
          }"
          class="scrollbar-base mt-2">
          <div
            v-for="(item, index) in productComments"
            :key="index"
            :class="{ 'mt-3': index !== 0 }"
            class="comment-list-item">
            <ProductComment
              :comment="item"
              @commentReplyCreate="commentReplyCreate"
              @productCommentLike="productCommentLike">
            </ProductComment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { productController } from '../../services/apiService';
import ProductCommentInput from './ProductCommentInput';
import ProductComment from './ProductComment';

export default {
  data () {
    return {
      commentsCount: 0,
      productComments: [],
      productCommentsQuery: {
        productId: null,
        skip: null,
        take: 10,
        IsAscending: false,
      },
      isCommentsVisible: false,

      content: {
        ru: {
          commentsCount: 'Комментарии',
          showComments: 'Показать',
          hideComments: 'Спрятать',
        },
        en: {
          commentsCount: 'Comments',
          showComments: 'Show',
          hideComments: 'Hide',
        },
      },
    }
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    productCommentsCount: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductCommentInput,
    ProductComment,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    productCommentsSkip() {
      return this.productComments.length;
    },
  },
  methods: {
    init() {
      this.commentsCount = this.productCommentsCount;
      this.productCommentsQuery.productId = this.productId;
    },
    initProductComments() {
      this.productCommentsQuery.skip = this.productCommentsSkip;
      productController.getProductCommentsPublicHttpPost(this.productCommentsQuery)
        .then((data) => {
          data.forEach(item => {
            this.productComments.push(item);
          });
        });
    },
    commentCreate(value) {
      productController.сreateProductCommentHttpPost({ productId: this.productId, content: value })
        .then((data) => {
          this.productComments.unshift(data);
          this.commentsCount++;
          if (!this.isCommentsVisible) {
            this.isCommentsVisible = true;
          }
        });
    },
    commentReplyCreate(reply) {
      productController.сreateProductCommentHttpPost({ productId: this.productId, content: reply.content, productCommentParentId: reply.commentId })
        .then((data) => {
          this.commentsCount++;
          this.productComments.unshift(data);
        });
    },
    productCommentLike(like) {
      productController.likeProductCommentHttpPost(like.commentId, like.isLike)
        .then((data) => {
          for (let i = 0; i < this.productComments.length; i++) {
            let productComment = this.productComments[i];
            if (productComment.id === data.id) {
              productComment.likes = data.likes;
              productComment.dislikes = data.dislikes;
              productComment.userContextIsLike = data.userContextIsLike;
              break;
            }
          }
        });
    },
    showComments() {
      if (this.productCommentsSkip === 0 && !this.isCommentsVisible) {
        this.initProductComments();
      }
      this.isCommentsVisible = !this.isCommentsVisible;
    },
  }
}
</script>

<style lang="scss" scoped>
.comment-box-block {
  position: relative;
  .comment-count {
    position: relative;
  }
  .comment-box {
    position: relative;
    .comment-list-single {
      height: 230px;
    }
    .comment-list {
      position: relative;
      height: 400px;
      .comment-list-item {
        margin-right: 5px;
      }
    }
  }
}
</style>
