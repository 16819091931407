<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3 pt-3">
      <div v-for="(item, index) in subscriptions" :key="index">
        <Subscription :item="item"></Subscription>
      </div>
    </div>
  </div>
</template>

<script>

import { userController } from '../../services/apiService';
import Subscription from '../../components/Subscription';

export default {
  data () {
    return {
      subscriptions: [],

      content: {
        ru: {
          title: 'Подписки',
        },
        en: {
          title: 'Subscriptions',
        },
      },
    }
  },
  components: {
    Subscription,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  methods: {
    init() {
      userController.getSubscriptionsHttpGet()
        .then((data) => {
          this.subscriptions = data;
        });
    },
  },
}
</script>

<style scoped>
</style>
