<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3">
      <div class="block-width-fixed">
        <div v-if="!isRegistered">
          <InputBox
            :modelValue="email"
            @update:modelValue="email = $event"
            title="Email"
            type="text"
            :isError="emailError"
            :messageError="content[lang].emailError">
          </InputBox>
          <InputBox
            :modelValue="name"
            @update:modelValue="name = $event"
            :title="content[lang].name"
            type="text"
            :isError="nameError"
            :messageError="content[lang].nameError">
          </InputBox>
          <PasswordCreate @result="passwordResult"></PasswordCreate>
          <div class="small-text text-center mt-3">
            <div>
              <span>{{ content[lang].agreementNote }}</span>
            </div>
            <div class="mt-2">
              <span @click="terms" class="bolder pointer noselect underline">{{ content[lang].agreementTerms }}</span>
            </div>
            <div class="mt-2">
              <span @click="privacyPolicy" class="bolder pointer noselect underline">{{ content[lang].privacyPolicy }}</span>
            </div>
          </div>
          <div class="pt-3 text-center">
            <button
              @click="register"
              :disabled="registerDisabled" type="button"
              class="btn button-default btn-success">{{ content[lang].register }}
            </button>
          </div>
        </div>
        <div v-else class="pt-3">
          <div class="text-center">
            <span>{{ content[lang].registered(name, email) }}</span>
          </div>
          <div class="pt-3">
            <UserActivation :emailProp="email"></UserActivation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { checkEmail } from '../../services/validateService';
import PasswordCreate from '../../components/PasswordCreate';
import UserActivation from '../../components/UserActivation';
import { userController } from '../../services/apiService';
import InputBox from '../../components/InputBox';

export default {
  data () {
    return {
      email: '',
      name: '',
      password: '',
      isRegistered: false,

      content: {
        ru: {
          title: 'Регистрация',
          enterEmail: 'Введите электронную почту',
          name: 'Имя',
          enterName: 'Введите имя',
          register: 'Зарегистрироваться',
          emailError: 'Неверный email',
          nameError: 'Имя должно быть указано',
          registered: (name, email) => `Пользователь "${name}" зарегистрирован. На вашу почту "${email}" выслан код подтверждения`,
          agreementNote: 'Нажимая на кнопку регистрации, Вы соглашаетесь с ',
          agreementTerms: 'Условиями и положениями',
          privacyPolicy: 'Политика конфиденциальности',
        },
        en: {
          title: 'Registration',
          enterEmail: 'Enter email',
          name: 'Name',
          enterName: 'Enter name',
          register: 'Register',
          emailError: 'Incorrect email',
          nameError: 'Name must be specified',
          registered: (name, email) => `User "${name}" is registered. A confirmation code has been sent to your mail "${email}"`,
          agreementNote: 'By clicking on the registration button, you agree to the ',
          agreementTerms: 'Terms and conditions',
          privacyPolicy: 'Privacy policy',
        } ,
      },
    }
  },
  components: {
    InputBox,
    PasswordCreate,
    UserActivation,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
    nameError() {
      if (this.name.length === 0) {
        return true;
      }
      return false;
    },
    registerDisabled() {
      if (!this.emailError && !this.nameError && this.password.length > 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    passwordResult(password) {
      this.password = password;
    },
    register() {
      userController.createUserHttpPost(this.email, this.name, this.password)
        .then(() => {
          this.isRegistered = true;
        });
    },
    terms() {
      this.$router.push('/terms');
    },
    privacyPolicy() {
      this.$router.push('/privacy-policy');
    },
  },
}
</script>

<style lang="scss">
</style>
