<template>
  <ModalBase ref="LanguageModal">
    <div class="picker-box">
      <PickerBox
        @change="changeLanguage"
        :options="languageOptions">
      </PickerBox>
   </div>
  </ModalBase>
</template>

<script>

import ModalBase from '../modals/ModalBase.vue';
import PickerBox from '../PickerBox.vue';

export default {
  data() {
    return {
    };
  },
  components: {
    PickerBox,
    ModalBase,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    languageOptions() {
      return [
        { text: 'English', value: 'en', selected: this.lang === 'en' },
        { text: 'Russin', value: 'ru', selected: this.lang === 'ru' },
      ];
    },
    languageModalActive() {
      return this.$store.state.languageModalActive;
    },
  },
  watch: {
    languageModalActive() {
      if (this.languageModalActive) {
        this.show();
      }
    },
  },
  methods: {
    show() {
      this.$refs.LanguageModal.show();
      this.$store.dispatch('changeLanguageModalActive', false);
    },
    hide() {
      this.$refs.LanguageModal.hide();
    },
    changeLanguage(data) {
      this.$store.dispatch('changeLanguage', data);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-box {
  min-width: 150px;
}
</style>
