<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div v-if="user" class="col-md-6 offset-md-3 pt-3">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>
              {{ content[lang].age }}
            </td>
            <td>
              <InputBox
                :modelValue="age"
                @update:modelValue="age = $event"
                :content="age"
                type="number"
                :isError="ageError"
                :messageError="content[lang].incorrectValue"
                :marginBottomZero="true">
              </InputBox>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].gender }}
            </td>
            <td>
              <SelectBox
                :modelValue="gender"
                @update:modelValue="gender = $event"
                :model="gender"
                :options="content[lang].genderOptions">
              </SelectBox>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].isMarried }}
            </td>
            <td>
              <SelectBox
                :modelValue="isMarried"
                @update:modelValue="isMarried = $event"
                :model="isMarried"
                :options="content[lang].isMarriedOptions">
              </SelectBox>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].country }}
            </td>
            <td>
              <SelectBox
                :modelValue="countryId"
                @update:modelValue="countryId = $event"
                :model="countryId"
                :options="countriesOptions">
              </SelectBox>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].description }}
            </td>
            <td>
              <textarea
                v-model="description">
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-3 text-center">
        <button
          @click="updateUser"
          type="button"
          :disabled="updateDisabled"
          class="btn button-default btn-success">{{ content[lang].update }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { userController } from '../../services/apiService';
import SelectBox from '../../components/SelectBox';
import InputBox from '../../components/InputBox';

export default {
  data () {
    return {
      age: null,
      gender: null,
      countryId: null,
      isMarried: null,
      description: null,
      countries: [],

      content: {
        ru: {
          title: 'Редактировать профиль',
          age: 'Возраст',
          gender: 'Пол',
          isMarried: 'Семейное положение',
          description: 'Описание',
          country: 'Страна',
          update: 'Обновить',
          profileUpdated: 'Профиль обновлен',
          isMarriedOptions: [
            { text: 'Не в браке', value: 'No' },
            { text: 'В браке', value: 'Yes' },
          ],
          genderOptions: [
            { text: '-', value: 'None' },
            { text: 'Мужской', value: 'Man' },
            { text: 'Женский', value: 'Woman' },
          ],
          incorrectValue: 'Некорректное значение',
        },
        en: {
          title: 'Edit Profile',
          age: 'Age',
          gender: 'Gender',
          isMarried: 'Family status',
          description: 'Description',
          country: 'Country',
          update: 'Update',
          profileUpdated: 'Profile was updated',
          isMarriedOptions: [
            { text: 'Not married', value: 'No' },
            { text: 'Married', value: 'Yes' },
          ],
          genderOptions: [
            { text: '-', value: 'None' },
            { text: 'Man', value: 'Man' },
            { text: 'Woman', value: 'Woman' },
          ],
          incorrectValue: 'Incorrect value',
        },
      },
    }
  },
  components: {
    SelectBox,
    InputBox,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    ageError() {
      if ((this.age !== null || this.age !== '') && (parseInt(this.age) <= 0 || parseInt(this.age) > 160)) {
        return true;
      }
      return false;
    },
    updateDisabled() {
      if (this.ageError) {
        return true;
      }
      return false;
    },
    user() {
      return this.$store.getters.getUser;
    },
    countriesOptions() {
      let countriesOptions = [];
      if (this.countries) {
        countriesOptions.push({ text: '-', value: null });
        const isName = this.lang === 'en';
        this.countries.forEach(country => {
          countriesOptions.push({
            text: isName ? country.name : country.description,
            value: country.id,
          });
        });
      }
      return countriesOptions;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.age = this.user.age;
      this.gender = this.user.gender;
      this.isMarried = this.user.isMarried;
      this.description = this.user.description;
      this.countryId = this.user.country ? this.user.country.id : null;
      userController.getCountriesCacheHttpGet()
      .then((data) => {
        this.countries = data;
      });
    },
    updateUser() {
      userController.updateUserHttpPatch(this.age, this.gender, this.countryId, this.isMarried, this.description)
        .then((data) => {
          this.user.age = data.age;
          this.user.gender = data.gender;
          this.user.isMarried = data.isMarried;
          this.user.description = data.description;
          this.user.country = data.country;
          const notification = {
            content: this.content[this.lang].profileUpdated,
          };
          this.$store.dispatch('setNotification', notification);
        });
    },
  },
}
</script>

<style scoped lang="scss">
textarea {
  width: 100%;
  height: 200px;
}
</style>
