<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="text-center mt-3">
      <div class="user-image-block">
        <div v-if="userImage" :style="{ 'background-image': 'url(' + userImage + ')' }" class="user-image-card user-image-default"></div>
      </div>
    </div>
    <div v-if="user" class="col-md-6 offset-md-3 mt-1">
      <div v-if="isAuthorized && !user.isCurrentUserProfile && userId" class="mt-2 mb-2 text-center">
        <button
          v-if="!user.isCurrentUserSubscribed"
          @click="subscribe"
          type="button"
          class="btn button-default btn-success">{{ content[lang].subscribe }}
        </button>
        <button
          v-else
          @click="unsubscribe"
          type="button"
          class="btn button-default btn-secondary">{{ content[lang].unsubscribe }}
        </button>
      </div>
      <table class="table table-bordered table-symmetrical mt-3">
        <tbody>
          <tr>
            <td>
              {{ content[lang].name }}
            </td>
            <td>
              {{ user.name }}
            </td>
          </tr>
          <tr
            v-on="user.productsCount > 0 ? { click: showUserProducts } : {}"
            :class="{ 'block-hover': user.productsCount }">
            <td>
              {{ content[lang].products }}
            </td>
            <td>
              {{ user.productsCount }}
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].subscribers }}
            </td>
            <td>
              {{ user.subscribers }}
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].country }}
            </td>
            <td>
              <span v-if="user.country">
                <span v-if="lang === 'en'">{{ user.country.name }}</span>
                <span v-else>{{ user.country.description }}</span>
              </span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].description }}
            </td>
            <td>
              <span v-if="user.description">{{ user.description }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>
              {{ content[lang].registrationDate }}
            </td>
            <td>
              {{ dateUTCToLocalFunc(user.created) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import { userController } from '../../services/apiService';
import { dateUTCToLocal, getFullPathWithScreenSize, getUserImageDefault } from '../../services/workService';

export default {
  data () {
    return {
      userId: null,
      user: null,
      userImage: null,

      content: {
        ru: {
          title: 'Просмотр профиля пользователя',
          name: 'Имя',
          country: 'Страна',
          description: 'Описание',
          products: 'Товары',
          subscribers: 'Подписчиков',
          registrationDate: 'Дата регистрации',
          subscribe: 'Подписаться',
          unsubscribe: 'Отписаться',
        },
        en: {
          title: 'User\'s profile view',
          name: 'Name',
          country: 'Country',
          description: 'Description',
          products: 'Products',
          subscribers: 'Subscribers',
          registrationDate: 'Registration date',
          subscribe: 'Subscribe',
          unsubscribe: 'Unsubscribe',
        },
      },
    }
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.userId = this.$route.query.userId;
      if (this.userId) {
        userController.getUserPublicHttpGet(this.userId)
          .then((data) => {
            this.user = data;
            this.setUserImage();
          });
      }
    },
    setUserImage() {
      if (this.user && this.user.userImage) {
        this.userImage = getFullPathWithScreenSize(this.user.userImage);
      } else {
        this.userImage = getUserImageDefault();
      }
    },
    subscribe() {
      userController.subscribeUserHttpPost(this.userId)
        .then(() => {
          this.user.isCurrentUserSubscribed = true;
          this.user.subscribers += 1;
        });
    },
    unsubscribe() {
      userController.unsubscribeUserHttpPost(this.userId)
        .then(() => {
          this.user.isCurrentUserSubscribed = false;
          this.user.subscribers -= 1;
        });
    },
    showUserProducts() {
      this.$router.push({ path: '/products', query: { userName: this.user.name } });
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
  },
}
</script>

<style scoped lang="scss">
.user-image-block {
  .user-image-card {
    display: inline-block;
    width: 150px;
    height: 150px;
  }
}
.table-symmetrical {
  td {
    width: 50%;
  }
}
</style>
