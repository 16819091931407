<template>
  <div @click="navigation" class="product-block block-default block-hover">
    <div class="product-card bolder text-center">
      <span class="product-name">{{ content[lang].title }}</span>
    </div>
    <div v-if="item.imageUrl" class="product-card">
      <div class="product-image-card">
        <img class="product-image" :src="item.imageUrl">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

      content: {
        ru: {
          title: 'Реклама',
        },
        en: {
          title: 'Advertisement',
        },
      },
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  methods: {
    navigation() {
      window.open(this.item.url, 'target_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
.product-block {
  position: relative;
  min-height: 100px;
  padding: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  .product-card {
    margin-bottom: 10px;
  }
  .product-image-card {
    text-align: center;
    .product-image {
      border-radius: 6px;
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }
}
</style>
