<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="text-center col-md-6 offset-md-3">
      <div class="pt-3">
        {{ content[lang].info }}
      </div>
      <!-- <div class="pt-3">
        <button
          @click="support"
          class="btn button-default btn-success">{{ content[lang].support }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      content: {
        ru: {
          title: 'О проекте',
          info: 'Платформа для независимых мнениях о продуктах. Поделитесь своим опытом и историями',
          support: 'Поддержать проект',
        },
        en: {
          title: 'About project',
          info: 'A platform for independent opinions on products. Share your experiences and stories',
          support: 'Support the project',
        },
      },
    }
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
  },
  methods: {
    // support() {
    //   window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=WWZNG8YD259F6&source=url', '_blank');
    // },
  },
}
</script>

<style scoped>
</style>
