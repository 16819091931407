<template>
  <div class="picker-box bolder">
    <div 
      @click="change(item)"
      class="picker-card"
      :class="{ 'picker-card-selected': item.selected }"
      :style="{ height: cardHeight + 'px' }"
      v-for="(item, index) in options" :key="index">
      <div v-if="index > 0" class="picker-card-line"></div>
      <span class="picker-card-content">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    cardHeight: {
      type: Number,
      default: 60,
    },
  },
  methods: {
    change(item) {
      this.$emit('change', item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-box {
  .picker-card {
    position: relative;
    cursor: pointer;
    transition: all 500ms ease;
    .picker-card-content {
      text-align: center;
      width: 50%;
      height: 50%;
      overflow: auto;
      margin: auto;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
    }
  }
  .picker-card:hover {
    background: rgba(0, 0, 0, 0.125);
  }
  .picker-card-selected {
    background: rgba(0, 0, 0, 0.08);
  }
  .picker-card-line {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}
</style>
