<template>
  <transition name="fade">
    <div
      @click="clearNotification"
      v-if="notificationContent"
      :class="{ 'error': isError }"
      class="notification">
      <div>
        <span>{{ notificationContent }}</span>
      </div>
      <div v-if="notificationValue">
        <span>{{ notificationValue }}</span>
      </div>
    </div>
  </transition>
</template>

<script>

import { ErrorsContent } from '../consts/errors';
import { NotificationTypes } from '../consts/notificationTypes';

export default {
  data () {
    return {
      timer: null,
      notificationType: null,
      notificationContent: null,
      notificationValue: null,
      errorsContent: ErrorsContent,
    }
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    notification() {
      return this.$store.state.notification;
    },
    isError() {
      return this.notificationType === NotificationTypes.error || this.notificationType === NotificationTypes.serverError;
    },
  },
  watch: {
    notification() {
      this.notificationEvent();
    },
  },
  methods: {
    notificationEvent() {
      if (this.notification) {
        this.init();
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(this.clearNotification, 5000);
      }
    },
    init() {
      this.notificationType = this.notification.type;
      if (this.notification.value) this.notificationValue = this.notification.value;
      if (this.notificationType === NotificationTypes.error) {
        this.notificationContent = this.errorsContent[this.lang][this.notification.content];
      } else {
        this.notificationContent = this.notification.content;
      }
    },
    clearNotification() {
      this.$store.dispatch('setNotification', null);
      this.notificationType = null;
      this.notificationContent = null;
      if (this.notificationValue) this.notificationValue = null;
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  cursor: pointer;
  width: 250px;
  z-index: 10;
  position: fixed;
  right: 20px;
  top: 80px;
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #21880dcc;
  word-wrap: break-word;
  text-align: center;
}
.error {
  background-color: #a92121cc;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
