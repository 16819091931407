<template>
  <div>
    <MenuMobile v-if="isMobile" :items="items"></MenuMobile>
    <MenuDesktop v-else></MenuDesktop>
  </div>
</template>

<script>

import { checkMobileScreen } from '../services/validateService';
import MenuMobile from './MenuMobile';
import MenuDesktop from './MenuDesktop';

export default {
  data () {
    return {
      isMobile: false,
    }
  },
  components: {
    MenuMobile,
    MenuDesktop,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.resizeEvent();
    window.addEventListener("resize", this.resizeEvent);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeEvent);
  },
  methods: {
    resizeEvent() {
      this.isMobile = checkMobileScreen();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
