<template>
  <ModalBase
    ref="ImageModal"
    @clickOutside="clickOutside"
    @click="hide">
    <div class="image-modal pointer">
      <img class="image" v-if="imageModal" :src="imageModal">
    </div>
  </ModalBase>
</template>

<script>

import ModalBase from '../modals/ModalBase.vue';

export default {
  data() {
    return {
    };
  },
  components: {
    ModalBase,
  },
  computed: {
    imageModal() {
      return this.$store.getters.getImageModal;
    },
  },
  watch: {
    imageModal() {
      if (this.imageModal) {
        this.show();
      }
    },
  },
  methods: {
    show() {
      this.$refs.ImageModal.show();
    },
    hide() {
      this.$refs.ImageModal.hide();
      this.clearimageModal();
    },
    clickOutside() {
      this.clearimageModal();
    },
    clearimageModal() {
      this.$store.dispatch('setImageModal', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-modal {
  .image {
    width: 100%;
  }
}
</style>
