<template>
  <div :class="{ 'margin-bottom-zero': marginBottomZero }" class="form-group">
    <label v-if="titleValue">{{ titleValue }}</label>
    <textarea v-if="isTextarea"
      v-model="contentValue"
      @input="handleInput"
      :disabled="disabled"
      class="form-control text-area"
      />
    <input
      v-else
      v-model="contentValue"
      @input="handleInput"
      :type="isPasswordVisible ? 'text' : type"
      :class="{ password: type === types.password }"
      :disabled="disabled"
      :placeholder="placeholder"
      class="form-control">
      <small
        class="text-danger"
        v-if="messageError && isError && isDirty">
        {{ messageError }}
      </small>
      <div
        v-if="type === types.password"
        @click="passwordEyeEvent"
        class="password-icon">
        <span
          v-show="!isPasswordVisible">
          <img src="@/assets/icons/eye.svg">
        </span>
        <span
          v-show="isPasswordVisible">
          <img src="@/assets/icons/eye-slash.svg">
        </span>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDirty: false,
      titleValue: '',
      contentValue: '',
      isPasswordVisible: false,
      types: {
        text: 'text',
        password: 'password',
        number: 'number',
      }
    };
  },
  props: {
    modelValue: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    messageError: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isTextarea: {
      type: Boolean,
      default: false,
    },
    marginBottomZero: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init();
  },
  watch: {
    title() {
      this.titleValue = this.title;
    },
    content() {
      this.contentValue = this.content;
    },
  },
  methods: {
    init() {
      this.titleValue = this.title;
      this.contentValue = this.content;
    },
    handleInput() {
      if (!this.isDirty) {
        this.isDirty = true;
      }
      this.$emit('update:modelValue', this.contentValue);
    },
    passwordEyeEvent() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-bottom-zero {
  margin-bottom: 0;
}
.form-group {
  position: relative;
  .password-icon {
    width: 20px;
    position: absolute;
    right: 10px;
    top: 37px;
    cursor: pointer;
  }
  .text-area {
    min-height: 100px;
  }
}
</style>
