<template>
  <div>
    <InputBox
      @input="inputPassword"
      :modelValue="password"
      @update:modelValue="password = $event"
      :title="content[lang].password"
      type="password"
      :isError="passwordError"
      :messageError="content[lang].passwordError">
    </InputBox>
  </div>
</template>

<script>

import { checkPassword } from '../services/validateService';
import InputBox from './InputBox';

export default {
  data () {
    return {
      password: '',

      content: {
        ru: {
          password: 'Пароль',
          passwordError: 'Пароль должен быть 6 и более символов',
        },
        en: {
          password: 'Password',
          passwordError: 'Password must be 6 and more characters',
        },
      },
    }
  },
  components: {
    InputBox,
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    passwordError() {
      return this.isPasswordError(this.password);
    },
  },
  methods: {
    isPasswordError(password) {
      return !checkPassword(password);
    },
    inputPassword() {
      let passwordResult = '';
      if (!this.passwordError) {
        passwordResult = this.password;
      }
      this.$emit('result', passwordResult);
    },
  },
}
</script>

<style scoped>
</style>
