<template>
  <div>
    <div class="text-center bolder">
      {{ content[lang].title }}
    </div>
    <div class="col-md-6 offset-md-3">
      <div class="block-width-fixed">
        <InputBox
          :modelValue="email"
          @update:modelValue="email = $event"
          :content="email"
          title="Email"
          type="text"
          :isError="emailError"
          :messageError="content[lang].emailError"
          @keyup.enter="login">
        </InputBox>
        <InputBox
          :modelValue="password"
          @update:modelValue="password = $event"
          :title="content[lang].password"
          type="password"
          :isError="passwordError"
          :messageError="content[lang].passwordError"
          @keyup.enter="login">
        </InputBox>
        <div class="text-right pointer bolder noselect">
          <span @click="passwordChange">{{ content[lang].forgotPassword }}</span>
        </div>
        <div class="pt-3 text-center">
          <button
            @click="login"
            :disabled="loginDisabled" type="button"
            class="btn button-default btn-success">{{ content[lang].title }}
          </button>
        </div>
        <div v-if="loginErrors.length" class="text-danger text-center pt-1">
          <div v-if="loginErrors.length && loginErrors.includes(errorCodes.USER_NOT_ACTIVE)" class="mt-3">
            <button
              @click="activation"
              type="button"
              class="btn button-default btn-success">{{ content[lang].activation }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { checkEmail, checkPassword } from '../../services/validateService';
import { userController } from '../../services/apiService';
import { ErrorCodes } from '../../consts/errors';
import InputBox from '../../components/InputBox';

export default {
  data () {
    return {
      email: '',
      password: '',
      loginErrors: [],
      errorCodes: ErrorCodes,

      content: {
        ru: {
          title: 'Войти',
          password: 'Пароль',
          emailError: 'Неверный email',
          passwordError: 'Пароль должен быть более 5 символов',
          activation: 'Активация аккаунта',
          forgotPassword: 'Забыли пароль',
        },
        en: {
          title: 'Login',
          password: 'Password',
          emailError: 'Incorrect email',
          passwordError: 'Password must be more than 5 characters',
          activation: 'Account activation',
          forgotPassword: 'Forgot password',
        },
      },
    }
  },
  components: {
    InputBox,
  },
  created() {
    this.init();
  },
  computed: {
    lang() {
      return this.$store.state.language;
    },
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
    passwordError() {
      if (checkPassword(this.password)) {
        return false;
      }
      return true;
    },
    loginDisabled() {
      if (!this.emailError && !this.passwordError) {
        return false;
      }
      return true;
    },
  },
  methods: {
    init() {
      this.email = this.$route.query.email;
    },
    login() {
      if (this.loginDisabled) return;
      this.loginErrors = [];
      userController.loginUserHttpPost(this.email, this.password)
        .then((data) => {
          const tokenData = {
            tokenAccess: data.tokenAccess,
            tokenRefresh: data.tokenRefresh,
          };
          this.$store.dispatch('login', tokenData);
          this.$store.dispatch('setUser', data);
          this.$router.push('/products');
        })
        .catch((e) => {
          const response = e.response;
          if (response && response.status === 400 && response.data.errors) {
            response.data.errors.forEach(element => {
              this.loginErrors.push(element.description);
            });
          }
        });
    },
    passwordChange() {
      this.$router.push({ path: '/password-change', query: { email: this.email } });
    },
    activation() {
      this.$router.push({ path: '/activation', query: { email: this.email } });
    },
  },
}
</script>

<style scoped>
</style>
